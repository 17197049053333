// brand colors
$color-pando-green: #ffba0c;
$color-pando-blue: #E643F1;
$color-pando-blue-light: #E643F1;

// basic colors
$color-white: #fff;
$color-black: #2a135b;
$color-blue: $color-pando-blue;
$color-green: $color-pando-green;
$color-light: #7c81a3;
$color-grey-dark: #4a5776;
$color-red: #ff4f0f;
$color-orange: #ffb515;

// panels
$color-page: #2f3944;
$color-panel: rgba(#d6d8ff, 0.05);

// Fonts
$color-font-main: #ffffff;
$color-font-light: #000;
$color-font-highlight: $color-pando-blue;

$color-font-title: $color-font-main;
$color-font-body: $color-font-main;
$color-font-link: $color-font-main;
$color-font-link-hover: $color-font-highlight;
$color-font-button: $color-black;
$color-font-button-light: $color-font-main;

// Lines
$color-line: darken($color-light, 25%);
$color-line-main: $color-line;
$color-line-light: darken($color-light, 35%);

// Text select
$color-select-back: $color-font-highlight;
$color-select-type: $color-font-main;

// Forms
$color-input-background: rgba($color-light, 0.35);
$color-input-text: $color-font-main;
$color-input-placeholder: rgba($color-font-main, 0.5);
$color-input-border: $color-line-main;

// Tables
$color-table-row: rgba(#d6d8ff, 0.05);
$color-table-row-alt: rgba(#d6d8ff, 0.02);
$color-table-row-line: rgba(#d6d8ff, 0.12);

// Buttons
$color-button-light: rgba($color-light, 0.45);

//Gradient helpers
@mixin linearVerticalGradient($top, $bottom) {
  background: $top; /* Old browsers */
  background: -moz-linear-gradient(top, $top 0%, $bottom 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, $top),
    color-stop(100%, $bottom)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, $top 0%, $bottom 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, $top 0%, $bottom 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, $top 0%, $bottom 100%); /* IE10+ */
  background: linear-gradient(to bottom, $top 0%, $bottom 100%); /* W3C */
}

@mixin linearHorizontalGradient($left, $right) {
  background: $left; /* Old browsers */
  background: -moz-linear-gradient(left, $left 0%, $right 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, $left 0%, $right 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, $left 0%, $right 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

@mixin buttonGradient() {
  @include linearHorizontalGradient($color-pando-blue, $color-pando-green);
}

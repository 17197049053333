.stake-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
}


.stakes {
  width: 100%;
  text-align: center;
  .node-type {
    color: $color-font-light;
    // width: 112px;
    padding: 2px !important;
    // &:before {
    //   content: "";
    //   display: inline-block;
    //   top: 2px;
    //   position: relative;
    //   width: 13px;
    //   height: 13px;
    //   margin-right: 0.2rem;
    //   background-position: center center;
    //   background-repeat: no-repeat;
    //   background-size: contain;
    // }
  }
  &.half {
    width: 45%;
  }
  .title {
    display: inline-block;
    // margin-bottom: 55px;
    // font-family: $font-title;
    font-weight: $weight-bold;
    font-size: 1rem;
    letter-spacing: 1.5px;
    // bottom: 19px;
    font-size: 21px;
    // botto.titlem: 19px;
    // font-size: 21px;

  }
  .staked-prct {
    text-align: right !important;
  }
  .arrow-container {
    cursor: pointer;
    color: $color-pando-blue-light;
    padding: 10px;
    text-align: center !important;
    i {
      border: solid $color-font-light;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      margin: 0 5px;
    }

    .up {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }

    .down {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
  .total {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    color: $color-font-light;
    font-size: 0.875rem;
    padding: 0.75rem;
  }
  .empty {
    height: 0px;
    padding: 0;
    display: none;
  }
  @include breakpoint("tablet-wide", max) {
    .node-type {
      width: auto;
      padding: 10px!important;
      &:before {
        display: none;
      }
    }
  }
  @include breakpoint("phablet", max) {
    .half {
      width: 100%;
      margin: 30px 3px 0 3px;
    }
    .staked {
    }
  }
  @include breakpoint("phone-small", max) {
    // .node-type {
    //   width: 65px;
    // }
  }
}
.stake-tb .token .currency {
  float: right;
  &.left {
    float: left;
    color: #000
  }
  @include breakpoint("phablet", max) {
    &:before {
      top: 0;
    }
  }
}

.stake-tb tr:last-child {
  background-color: transparent !important;
  border-top: 3px solid $color-table-row-line;
  td.token {
    color: $color-white;
    font-size: 14px;
  }
}
.mt-5 {
  margin-top: 50px;
}

.node-type.vcp:before {
  background-image: url("../../../public/images/icons/icon_validator-node@2x.png");
}
.node-type.gcp:before {
  background-image: url("../../../public/images/icons/icon_guardian-node@2x.png");
}

.token.dashboard.pando {
  display: none;
}

.token.dashboard.PTX {
  margin-top: 0rem;
}
.demgraph.newdemo
{
  margin-top: 50px;
  border-radius: 8px;
  border: 0.5px solid #C6C4C4;
  background: #FFF;
  box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.25);
  h2{
    margin-bottom: 0;
  }
}
.demgraph.newdemo1{
  margin-top: 50px;
  h2{
    margin-bottom: 0;
  }
}
.far4{
  border-radius: 8px;
  border: 0.5px solid #C6C4C4;
  background: #FFF;
  box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.25);
}
.token.dashboard.ptx {
  display: none;
}

.token.dashboard {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  // border-top: 1px solid rgba($color-font-light, 0.2);

  // &.pando>:first-child{
  //   width: 4%;
  // }
  // &.PTX>:first-child{
  //   width: 4%;
  // }

  &.pando {
    .value.price {
      @include linearHorizontalGradient($color-blue, $color-green);
    }
  }

  &.PTX {

    // border-bottom: 1px solid rgba($color-font-light, 0.2);
    .value.price {
      @include linearHorizontalGradient($color-red, $color-orange);
    }
  }

  // > :last-child {
  //   width: 39% !important;
  // }

  .column {
    width: 50%;

    padding-top: 15px;

    > :first-child {
      // margin-bottom: 25px;
    }

    .currency {
      margin-top: 10px;

      &::before {
        width: 24px;
        height: 24px;
      }
    }

    .detail {
      background: transparent linear-gradient(180deg,#1d252d,#4d5d6d) 0 0 no-repeat padding-box;
      background: hsla(0,3%,39%,.38) 0 0 no-repeat padding-box;
      background: rgba(0,0,0,.51) 0 0 no-repeat padding-box;
      border-radius: 4px;
      border-radius: 14px;
      box-shadow: inset 0 0 31px hsla(0,0%,80%,.839);
      height: 87px;
      margin: 19px;
      opacity: 1;
      padding: 22px 5px;
      text-transform: uppercase;
       

      .title {
        color: #878A8C;
        font-size: 14px;
        text-align: left;
        // white-space: nowrap;
      }

      .value {
        display: flex;
        color: #010101;
        font-size: 18px;
        text-align: left;
        justify-content: left;
        // font-family: 'HelveticaNeueBold';

        &.price {
          font-size: 23px;
          font-weight: 100;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin: auto;
        }

        .tps {
          font-size: 12px;
          align-self: flex-end;
        }
      }
    }

    .detail:hover{
   
    -moz-transition: all 1s ease 0.5s;
    -o-transition: all 1s ease 0.5s;
    transition: all 1s ease 0.5s;
    }


    .chart-container {
      background: red !important;
      height: 300px;
      border-radius: 20px;
      padding: 20px;

      // padding-left: 10px;
      // border-left: 1px solid rgba($color-font-light, 0.2);
      // text-align: center;
      .title {
        color: #fff;
      }
    }
  }

  // new css start here
  .kiimcm {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid #dedede;
}

.kiimcm .detail {
  margin: 0px !important;
  padding-top: 0px !important;
  background: none !important;
    box-shadow: none !important;
    padding-bottom: 15px;
    height: auto;
}
.chart-container.homechart {
  padding: 0px;
  border-radius: 8px;
  margin-top: 20px;
  background: rgb(0 0 0 / 5%) 0% 0% no-repeat padding-box;
  box-shadow: none;
}
.homechart .chart .line {
  margin: 0px 0px !important;
  height: 202px !important;
}
.chart-container .title {
  padding: 10px;
  color: #010101;
}
.chart-container .title img {
  width: 35px;
}
.fornew3 .column{
  width: 100%;
}

.chart-container{}
  @include breakpoint("tablet-wide", max) {
    display: block;

    .column {
      width: 32%;
      display: inline-block;
      text-align: center;

      .value {
        display: block !important;
      }
    }

    > :last-child {
      width: 100% !important;
    }
  }

  @include breakpoint("tablet", max) {
    .column {
      width: 100%;
    }
  }
}
@media(max-width:1300px)
{
  .stak23 .demgraph.newdemo
  {
    width: 95%!important;
   
  }
  .token.dashboard .kiimcm .detail
  {
    height: 65px;
  }
}
// MODULES
@import "modules/colors";
@import "modules/fonts";
@import "modules/input-types";
@import "modules/breakpoints";

// PARTIALS
@import "partials/utils";
@import "partials/base";
@import "partials/layout";
@import "partials/forms";
@import "partials/buttons";
@import "partials/tables";
@import "partials/misc-ui";

// COMPONENTS
@import "components/header";
@import "components/not-exist";
@import "components/pagination";
@import "components/token-dashboard";
@import "components/transaction-table";
@import "components/blocks-table";
@import "components/loading-panel";
@import "components/spinner";
@import "components/json-view";
@import "components/stakes-table";
@import "components/chart";
@import "components/tabs";
@import "components/smart-contract";
@import "components/read-contract";

// SECTIONS
@import "sections/home";
@import "sections/transactions";
@import "sections/account";
@import "sections/blocks";
@import "sections/stakes";
@import "../../node_modules/react-tabs/style/react-tabs.scss";

.chart-container {
  // background: transparent linear-gradient(106deg, #12A2B8 0%, #003139 14%, #004853 39%, #000000 100%) 0% 0% no-repeat padding-box;
  // height: 350px;
  border-radius: 5px;
  padding: 30px;
  margin-top: 2rem;
  // padding-left: 10px;
  background: #00000082 0% 0% no-repeat padding-box;
  box-shadow: inset -6px -6px 31px #cdcdcdd6;
  border-radius: 29px;
  opacity: 1;

  .title {
    padding: 10px;
  }
}

.read-contract__output {
  color: #fff;
  font-size: 18px;
  margin-bottom: 25px;

  overflow: hidden;
  word-break: break-word;
}

.read-contract__outputs-template {
  margin-bottom: 10px;
}

.text-green {
  color: #47ff00;
}

.sjh8g {
  margin-left: 20px;
}

.sjh8g span {
  display: block;
  margin-top: 10px;
}

.read-contract__output--unit,
.read-contract__output--response {
  font-style: normal;
  color: #fff;
}

.read-contract__outputs {
  background: #000;
  padding: 20px;
  border-radius: 10px;
}

.actions-3 {
  padding-bottom: 60px;
}

.chart .line {
  margin: 30px 0px !important;
  height: 300px !important;
}

.download-section {
  h3 {
    text-align: center;
    margin-top: 5%;
  }

  ul {
    margin-top: 10%;
    list-style: none;
    display: flex;
    padding: 0px;

    li {
      margin: 10px;

      img {
        border-radius: 10px;
      }
    }

    li:hover {
      padding: 10px;
      background: #fff;
      border-radius: 10px;
      transition: background-color 0.1s ease-in;
    }
  }
}

.meta-mega-icon {
  display: flex;
  list-style: none;
  justify-content: center;

  li {
    margin: 10px;
  }
}

.home-intro {
  cursor: pointer;
  height: 100px;
}

.tran56 {
  text-transform: capitalize;
}

.home-intro:hover {
  box-shadow: 0 0 50px #fff;
  // transform: translateY(-5px);
}

::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
  background: #B6FFFE;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: #B6FFFE !important;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: 00A6A6 !important;
  background-color: 00A6A6 !important;
}

@media only screen and (max-width: 1200px) {
  .download-right {
    padding: 10px;
    width: 100% !important;
  }

  .custom-btn1 {
    width: 150px !important;
  }

  .download {
    display: inline !important;
  }

  .add {
    // display: block !important;
    max-width: 50px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .pando-header .nav a.nav-item {
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    // height: 333px;
  }

  .resource {
    float: left;
  }

  .resource {
    float: left;
  }

  .custom {
    float: left;
  }

  table.data.txn-table {
    top: 60px !important;
  }

  .custom-btn {
    left: -10px !important;
  }

  .total-rametron {
    top: 50px;
  }

  .cc {
    top: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .searchContainer .input {}

  .custom-btn1 {
    width: 150px !important;
  }

  .download {
    display: inline !important;
  }

  .add {
    // display: block !important;
    max-width: 50px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .cc {
    top: 30px;
  }

  .body-color {
    padding: 0px;
  }

  .chart .line {
    margin: 10px 0px !important;
  }

  .pando-header .nav,
  .pando-header .nav-search {
    max-height: 0;
    overflow: hidden;
    display: none;
    background: transparent linear-gradient(180deg, #1d252d, #4d5d6d) 0 0 no-repeat padding-box;
    position: absolute;
    top: 76px;
    width: 100%;
    color: #000;
    z-index: 9;
  }

  .pando-header .active-nav {
    padding: 10px;
    max-height: 500px;
    overflow: hidden;
  }

  .pando-header .nav-buttin {
    display: block;
  }

  .pando-header {
    display: block;
  }

  .pando-header .nav,
  .pando-header .nav-search {
    position: relative;
    top: 0px;
  }

  .pando-header .nav {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    top: -25px;
    text-transform: uppercase;
  }

  .pando-header .nav-search {
    margin: 0;
    top: -26px;
  }

  .pando-header .nav a.nav-item {
    display: flex;
    margin-right: 0;
    text-transform: uppercase;
  }

  .pando-header .nav p img {
    height: 25px;
    margin-top: -8px;
    margin-right: 10px;
  }

  .pando-header .nav-search .search-button {
    position: absolute;
    right: 10px;
    bottom: 21px;
  }

  .pando-header .nav-search .search-select {
    width: 80%;
    margin: 10px 0;
    background: transparent linear-gradient(180deg, #360046 0%, #004F51 100%) 0% 0% no-repeat padding-box;
  }

  .pando-header .nav-buttin:focus {
    outline: none;
  }

  .resource {
    float: left;
  }

  .dd-button {
    width: 100%;
    left: 0px;
    margin-bottom: 12px;
    right: 0px;
    margin-left: 0;
    margin-top: 10px;

  }

  .resource {
    float: left;
  }

  .custom {
    float: left;
  }

  .active-nav1 {
    right: 0px !important;
  }

  table.data.txn-table {
    top: 20px !important;
  }

  .custom-btn {
    left: -10px !important;
  }

  .total-rametron {
    top: 50px;
  }

  .download-right {
    padding: 10px;
    width: 100% !important;
  }
}

.custom-btn {
  border-radius: 10px;
  bottom: 30px;
  float: right;
  z-index: 0;
  padding: 10px 12px 8px;
}

.active-nav1 {
  right: 10%;
}

.custom-btn img {
  transition: all 0.25s;
  width: 30px;
}

.custom-btn:hover {
  transform: rotate(360deg) scale(1.1);
}

.ip-input {
  margin-top: 15px;
  width: 85%;
  height: 50px;
  // border: 1px dashed gray !important;
  border-radius: 26px !important;
}

.nodeStatus {
  background: #00000082 0% 0% no-repeat padding-box;
  box-shadow: inset -3px -3px 31px rgb(233 1 249 / 49%);
  opacity: 1;

  padding: 15px 37px;
  border-radius: 33px;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 23px;
}

.nodeStatusDisclaimer {
  width: 86%;
  margin-top: 5px;
  padding-top: 27px;
  font-weight: lighter;
  color: #fff;
  font-family: 'helveticaneuethin';
}

.custom-ip-btn {
  color: white;

  width: 36%;
  border-radius: 6px;
  width: 32%;
  background: #00000082 0% 0% no-repeat padding-box;
  box-shadow: inset -6px -6px 31px rgb(205 205 205 / 84%);
  border-radius: 47px;
  opacity: 1;
  //    -webkit-backdrop-filter: blur(30px);
  padding: 15px 20px;
  font-size: 18px;
}

.ip-btn-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.text-danger {
  top: 5px;
  color: red;
}

-webkit-appearance {}

.download-right {
  padding: 10px;
  width: 50%;
}

.download-page {
  padding: 80px 0;
}

.download-page .searchbar-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin: 0 auto 50px;
}

.download-page .searchbar-flex input {
  width: 100%;
  height: 55px;
  border-radius: 8px;
  padding: 0 20px;
  box-shadow: inset 0px 0px 31px #cdcdcdd6;
  background: linear-gradient(rgb(50 80 126 / 30%), rgb(23 32 87 / 80%));
  border: none;
  outline: none;
  margin-right: 10px;
  color: #fff;
}

.download-page .searchbar-flex input::placeholder {
  color: #fff;
}

.download-page .searchbar-flex select {
  background: #507ed0 0% 0% no-repeat padding-box;
  box-shadow: inset -4px -4px 31px #cdcdcdd6;
  width: 20%;
  height: 55px;
  outline: none;
  border: none;
  color: #fff;
  border-radius: 8px;
  position: relative;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
}

.download-page .searchbar-flex select option {
  background-color: #000;
}

.download-page .h-100 {
  // height: 100%;
  padding: 15px 30px;
}

.download-page .download-box {
  box-shadow: inset -3px -3px 31px rgb(233 1 249 / 49%);
  box-shadow: inset -6px -6px 31px #cdcdcdd6;
  text-align: center;
  border-radius: 22px;

  padding: 30px;
  margin-bottom: 20px;
  height: 100%;
  // width: 400px;
}

.download-page .download-box .title {
  color: #fff;
  font-size: 30px;
}

.download-page .download-box .title img {
  width: 34px;
  margin-left: 14px;
}

.download-page .download-box .des {
  color: #ffffff;
  font-size: 20px;
  padding: 15px 0;
}

.download-page .download-box button {
  /* background: transparent linear-gradient(252deg, #E643F1 0%, rgba(0, 0, 0, 0.1411764706) 100%) 0% 0% no-repeat padding-box; */
  /* box-shadow: inset -3px -3px 31px #e643f1; */
  /* border-radius: 44px; */

  padding: 18px 30px;
  color: #fff;
  font-size: 20px;
  position: relative;
  outline: none;
  background: #00000082 0% 0% no-repeat padding-box;
  box-shadow: inset -3px -3px 31px rgb(233 1 249 / 49%);
  opacity: 1;
  border: none;
  border-radius: 49px;
}

.custom-modal .modal-content {
  background: transparent linear-gradient(224deg, #00a1db2b 0%, #00000024 100%) 0% 0% no-repeat padding-box;
  box-shadow: inset -6px -6px 31px #cdcdcdd6;
  border: 1px solid #696969;
  border-radius: 22px;
  opacity: 1;
  padding: 20px;
}

.custom-modal .modal-content .close-btn i {
  color: #fff;
  text-align: end;
  display: block;
  font-size: 20px;
  cursor: pointer;
}

.custom-modal .modal-content .modal-box h4 {
  color: #fff;
  font-size: 30px;
  text-align: center;
}

.custom-modal .modal-content .modal-box h4 img {
  width: 20px;
  text-align: center;
}

.custom-modal .modal-content .modal-box p {
  color: #fff;
  font-size: 24px;
  padding: 40px 0;
  text-align: center;
}

.custom-modal .modal-content .modal-box .icon-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-modal .modal-content .modal-box .icon-flex button {
  background-color: transparent;
  border: none;
  display: grid;
  color: #fff;
  font-size: 20px;
}

.custom-modal .modal-content .modal-box .icon-flex button img {
  width: 60px;
  margin: 0 auto;
  display: block;
  padding-bottom: 10px;
}

.custom-modal .modal-content .modal-box .icon-flex button span {
  font-size: 16px;
  padding-top: 5px;
  color: #c2c2c2;
}

.custom-modal .modal-content .modal-box .icon-flex button:focus {
  background: transparent linear-gradient(224deg, #e643f14a 0%, #000000 100%) 0% 0% no-repeat padding-box;
  box-shadow: inset -6px -6px 31px #cdcdcd1f;
  border-radius: 10px;
}

.download-page-network .box {
  background: transparent linear-gradient(224deg, #00a1db2b 0%, #00000024 100%) 0% 0% no-repeat padding-box;
  box-shadow: inset -6px -6px 31px #cdcdcdd6;
  text-align: center;
  border-radius: 22px;
  padding: 30px;
  margin-bottom: 20px;
  height: 100%;
}

.downloadContainer {
  padding: 0px 100px;
}

.download-page-network {
  padding: 0 25px
}

.modalButton {
  background: #00000082 0% 0% no-repeat padding-box;
  box-shadow: inset -6px -6px 31px rgb(205 205 205 / 84%);
  border-radius: 47px;
  opacity: 1;
  color: white;
  padding: 15px 20px;
  font-size: 18px;
  margin: 0 auto;
}

.modal-header {
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: none;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  margin: 0 auto;
  height: 5rem;
}

.modalButton:hover {
  background: #000000 0% 0% no-repeat padding-box !important;
  box-shadow: inset -6px -6px 31px rgb(205 205 205 / 84%) !important;
}

.download-page-network .box h4 {
  color: #fff;
  padding-top: 25px;
  font-size: 28px;
}

.download-page-network .box p {
  font-size: 20px;
  color: #fff;
  padding: 10px 0;
}

.download-page-network .icon-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}

.download-page-network .icon-flex button {
  background-color: transparent;
  border: none;
  display: grid;
  color: #fff;
  font-size: 20px;
}

.download-page-network .icon-flex button img {
  width: 60px;
  margin: 0 auto;
  display: block;
  padding-bottom: 10px;
}

.download-page-network .icon-flex button span {
  font-size: 16px;
  padding-top: 5px;
  color: #c2c2c2;
}

.download-page-network .icon-flex button:focus {
  background: transparent linear-gradient(224deg, #e643f14a 0%, #000000 100%) 0% 0% no-repeat padding-box;
  box-shadow: inset -6px -6px 31px #cdcdcd1f;
  border-radius: 10px;
}

.btn-flex.allbutton {
  padding-right: 22px;
}

.download-page-network .img img {
  width: 100%;
}

@media (min-width: 1400px) and (max-width: 1600px) {}

@media (max-width: 1024px) {
  .logo {
    margin-left: 30px;
    margin-right: 30px;
  }

  .do-bth .nav-search {
    width: auto;
  }

  .data.block-table,
  .data.txn-table2,
  .details.txn-details,
  .details.txn-info,
  .txn-info.details,
  .data.transactions,
  .details.account-info,
  .data.txn-table2 {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
  }

  .table.details thead tr th:first-child {
    width: auto;
  }

  .stakes .title {
    font-size: 16px;
  }

  .content.account .actions .download {
    display: none;
    width: auto;
    height: auto;
    opacity: 1;
    visibility: inherit;
  }

  .content.account .actions {
    text-align: center;
    margin-bottom: 20px;
    /* font-size: 20px; */
  }

  .content.account .actions .btn.tx {
    font-size: 16px;
  }

  .nod-st .row {
    width: 100%;
    padding: 30px 10px;
  }

  .ip09 .ip-input {
    width: 100%;
  }
}

@media screen and (max-width: 1300px) {
  .searchContainer {
    display: flex;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
  }
}

@media screen and (max-width: 1200px) {
  .downloadContainer {
    // margin-left: 76px;
  }

  .download-page-network .btn-flex {
    display: flex;
    // justify-content: space-around;
  }

  .download-page-network .btn-flex button {
    margin-right: 14px;
  }

  .download-page-network .btn-flex button {
    width: 200px !important;
  }

  .dappsonpando .wrpas {
    padding: 5px;
    width: 50% !important;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    height: 250px;
  }

  .dappsonpando {
    margin: 10px !important;
    padding: 10px !important;
    display: block !important;
  }
}


.download-page-network .box p {
  font-size: 20px;
  color: #fff;
  padding: 10px 0;
}

.download-page-network .icon-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}

.download-page-network .icon-flex button {
  background-color: transparent;
  border: none;
  display: grid;
  color: #fff;
  font-size: 20px;
}

.download-page-network .icon-flex button img {
  width: 60px;
  margin: 0 auto;
  display: block;
  padding-bottom: 10px;
}

.download-page-network .icon-flex button span {
  font-size: 16px;
  padding-top: 5px;
  color: #C2C2C2;
}

.download-page-network .icon-flex button:focus {
  background: transparent linear-gradient(224deg, #e643f14a 0%, #000000 100%) 0% 0% no-repeat padding-box;
  box-shadow: inset -6px -6px 31px #cdcdcd1f;
  border-radius: 10px;
}

.download-page-network .btn-flex {
  display: flex;
  justify-content: center;
  // align-items: center;
}

.download-page-network .btn-flex button {
  background: transparent linear-gradient(252deg, #757c7e96 0%, #00000024 100%) 0% 0% no-repeat padding-box;
  box-shadow: inset 16px 9px 12px #ffffff2b, 0px 3px 6px #00000029;
  border: 1px solid #696969;
  border-radius: 18px;
  opacity: 1;
  padding: 18px 50px;
  color: #fff;
  font-size: 20px;
  margin-right: 10px;
}

.download-page-network .img img {
  width: 100%;
}

.navbar-expand-lg .navbar-nav .drop-men .dropdown-menu {
  padding-left: 10px;
}

.wlladd {
  font-size: 0.875rem;
  display: contents;
}



.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 10px;
  background: #D9D9D9;
  color: #727272;
  opacity: 1;
  color: #fff;
  border: 0;
  // text-transform: uppercase;
  padding: 10px;
  border-radius: 4px;
  margin-right: 5px;
}

.react-tabs__tab-list li {
  list-style: none;
}

.react-tabs__tab.react-tabs__tab--selected {
  background: #00A6A6 0% 0% no-repeat padding-box;
}

.pando-tabs .react-tabs__tab.react-tabs__tab--selected {
  // background: rgba(0, 105, 187, 0.84) 0% 0% no-repeat padding-box;
  // box-shadow: inset 0px 0px 15px rgb(205 205 205 / 47%);
}

.react-tabs__tab {
  color: #000;
}

.react-tabs__tab.react-tabs__tab--selected#react-tabs-2 {
  background: #00A6A6 0% 0% no-repeat padding-box;
  opacity: 1;
  color: #fff;
  border: 0;
}


.react-tabs__tab.react-tabs__tab--selected#react-tabs-4 {
  background: #00A6A6 0% 0% no-repeat padding-box;
  opacity: 1;
  color: #fff;
  border: 0;
}

.react-json-view {
  background-color: #035754 !important;
  box-shadow: none;
}

.pushed-content.object-container {
  font-size: 16px;
}

.select__selector select {
  color: white;
  padding: 8px 25px 6px 8px;
  font-size: 16px;
  height: auto;
  margin-left: 0px;
  background: #2e2e2e 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 0px 22px #cdcdcd69;
  border-radius: 33px;
  width: 100%;
  line-height: inherit;
  font-family: 'helvetica_neueregular';
  cursor: pointer;
}

table.data tbody tr .Coinbase.type:before {
  background-image: url("../../public/images/icons/icon_coinbase@2x.png");
  display: none;
}

table.data tbody tr .Pending.type:before {
  background-image: url("../../public/images/icons/icon_coinbase@2x.png");
  display: none;
}

.Pending.type {
  color: #E643F1;
}

table.data tbody tr .Transfer.type:before {
  background-image: url("../../public/images/icons/Group865.svg");
  display: none;
}

table.data tbody tr .Pending.type:before {
  background-image: url("../../public/images/icons/Group865.svg");
}

table.data tbody tr .Deposit.type:before {
  background-image: url("./../../public/images/icons/depositxsx.svg");
  display: none;
}

table.data tbody tr .Withdraw.type:before {
  background-image: url("./../../public/images/icons/Withdraw.svg");
  display: none;
}

table.data tbody tr .Smart.type:before {
  background-image: url("./../../public/images/icons/Smart contract.svg");
  display: none;
}

.colju .column {
  padding-top: 0 !important;
}

table.data tbody tr .type:before {
  content: "";
  display: inline-block;
  top: 1px;
  position: relative;
  width: 15px;
  height: 15px;
  margin-right: 0.5rem;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 100%;
}

.react-tabs__tab {
  border: 0;
  // text-transform: uppercase;
  padding: 10px;
  border-radius: 6px;
  margin-right: 5px;
  color: #727272;
  // background: #470C4B 0% 0% no-repeat padding-box;]
  // color: #E643F1;
  width: 195px;
  text-align: center;
}

.pando-tabs .react-tabs__tab--disabled {
  opacity: 0.7;
  color: #727272;
}

.react-tabs__tab.react-tabs__tab--selected {
  color: #fff;
}

.pretty-json-container.object-container {
  text-align: left;
}

.searchiconwrapper {
  position: relative;

  svg {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 50px;
    width: 40px;
    padding: 10px;
    cursor: pointer;
  }
}

.smart-contract.verified {
  .Smart.Contract.type {
    color: rgb(255 156 72) !important
  }
}

.ethverified {

  display: inline;
  vertical-align: middle;

  cursor: pointer;
  transition: 0.3s all ease-in-out;

  svg {
    color: rgb(255 156 72) !important
  }

  span {
    display: none;
    position: absolute;
    top: -12px;
    left: 12px;
    font-size: 14px;
    padding: 10px;
    background-color: #000;
    z-index: 99;
    color: #fff;
    border-radius: 10px;
  }

  &:hover {

    span {
      display: block;
    }
  }
}

.ace_content {
  padding: 10px;
}

.ace-monokai {
  background-color: #000;
  line-height: 20px;
  font-size: 14px !important;
}

.ace-monokai .ace_gutter {
  background: #000;
}

.node-status {
  .custom-btn.hom-ref {
    top: 0
  }
}

.copyNodeSummary {
  display: none;
  position: absolute;
  top: -9px;
  left: 30px;
  padding: 10px;
  background: #9e28a6 0% 0% no-repeat padding-box;
  color: #fff;
  border-radius: 10px;
  font-size: 14px;
  z-index: 99;
}

.nodssyn {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;

  &:hover {
    .copyNodeSummary {
      display: block;
    }
  }
}

.tosender {
  text-transform: none;
  margin-right: 20px;
  color: #00A6A6;
}

.mored {
  color: #00b3a8 !important;
  margin-left: 10px;
}

.dappsonpando {
  margin: 10px;
  padding: 10px;
  display: flex;

  .wrpas {
    width: 25%;
    padding: 10px;
    text-align: center;


    div {
      background: rgba(0, 0, 0, 0.5098039216) 0% 0% no-repeat padding-box;
      padding: 30px 15px;
      height: 100%;
      box-shadow: 0px 0px 3px rgb(205 205 205 / 84%);
      border-radius: 8px;

      img {
        max-height: 50px;
      }

      &:hover {
        border: 1px solid #fff;
      }

      P {
        margin: 0px;
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: 14px;
        line-height: 25px;
      }

      a {
        color: #ccc;
        cursor: pointer;

        &:hover {
          color: #fff
        }
      }
    }
  }

}

.viewmore {
  text-align: center;
  color: #E643F1;
  display: block;
  margin-bottom: 80px;
  cursor: pointer;
}

.dasa {

  h4 {
    text-align: center;
    margin-top: 80px;
  }
}


.nodetypws {
  display: flex;
  align-items: center;
  overflow: auto;
  margin: 10px;

  .nodetypwschild {
    display: inline-flex;
    cursor: pointer;
    border: 0;
    padding: 10px 15px;
    border-radius: 6px;
    margin-right: 10px;
    background: rgba(0, 0, 0, 0.5098039216) 0% 0% no-repeat padding-box;
    box-shadow: none;
    color: #fff;
    border-radius: 11px;
    border: 1px solid #B5D2D2;
    background: #DAF1F1;
    color: #639393;

    margin-bottom: 10px;

    &:hover {
      // background: #00aca7 0% 0% no-repeat padding-box;
    }
  }

  .nodetypwschild.active {
    background: #00aca7 0% 0% no-repeat padding-box;
    color: #fff;

  }
}

.nodedetails {
  display: flex;
  margin-bottom: 15px;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5098039216) 0% 0% no-repeat padding-box;
  box-shadow: inset -6px -6px 31px rgb(205 205 205 / 84%);
  border-radius: 22px;
  width: 100%;

  div {
    width: 30%;
    margin: 15px;

    span {
      display: block;
    }

    span.headingsofnode {
      font-size: 22px;
      margin-bottom: 10px;
      font-weight: 500;
    }
  }
}

// new homepage css
.rant5 {
  margin-top: 60px;
  border-radius: 8px;
  border: 0.5px solid #C6C4C4;
  background: #FFF;
  box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.25);
  text-align: center;
}

.kiimcm .side-icon {
  align-self: center;
}

.side-icon img {
  margin-top: -15px;
}

.side-icon.y67 img {
  padding: 0px 5px;
}

.footer-link li a,
.cpyy a {
  color: #000;
}

.footer-link li a:hover,
.cpyy a:hover,
.input-field a:hover {
  color: #20B1B1;
}

.rant5 h2 {
  border-radius: 8px 8px 0px 0px;
  background: #E1FCFC;
  color: #20B1B1;
  font-size: 24px;
  padding: 15px;
  font-weight: 700;
  margin-bottom: 22px;
}

.all-in1 {
  padding: 20px;
}

.rant5 h3 {
  color: #AEADAD;
  font-weight: 600;
}

.rant5 p {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #B0B0B0;
}

.token.dashboard.newdashh {
  display: inherit !important;
}

.page-title.blocks a,
.page-title.ui7 a {
  color: #000000;
  font-weight: 600;
  font-size: 18px;
  text-transform: capitalize;
}

h2.blokss2 {
  text-align: left !important;
  margin-bottom: 0px !important;
  letter-spacing: 0px;
  border-bottom: 1px solid #cacaca;
  border-radius: 0px !important;
  margin: 6px 0px !important;
}

table.data tbody tr:hover td {
  background-color: rgb(255 255 255);
}

.Transfer.type {
  color: #000;
}

td.hash.overflow.hasgfr4 {
  // display: flex;
}

// blocks table css
.lzi8 p {
  color: #878A8C;
  /* font-family: Inter; */
  font-size: 14px;
  margin: 0px;
}

.flx {
  display: flex;
}

.lzi8 {
  padding-left: 5px;
  align-self: center;
}

.valii {
  color: #000;
}

.nob span {
  color: #878A8C;
  font-size: 14px;
}

p.nob {
  margin: 0;
}

.fintr {
  text-align: right;
}

.fintr img {
  width: 88px;
}

.flxfrom {
  color: #000;
  padding-right: 3px;
}

// Wallet overview section
.whsts {
  background-image: url("./../../public/images/new-design/wallet-overr.png");
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  border-radius: 20px;
}

.whsts h1 {
  color: #FFF;
  font-size: 48px;
  font-weight: 800;
  padding: 123px 0px 100px;
  height: 315px;
}

.top-60 {
  margin-top: 60px;
}

.whsts.blckss {
  background-image: url("./../../public/images/new-design/blokbanner.png");
}

.whsts.stakess1 {
  background-image: url("./../../public/images/new-design/Maskgroup22.svg");
}

.whsts.transs {
  background-image: url("./../../public/images/new-design/trans.svg");
}

.whsts.topnode1 {
  background-image: url("./../../public/images/new-design/Mask group.svg");
  position: absolute;
  width: 100%;
  top: 0;
}

.whsts.topnode2 {
  background-image: url("./../../public/images/new-design/Maskgroup2.svg");
  position: absolute;
  width: 100%;
  top: 0;
}

.whsts.topnode3 {
  background-image: url("./../../public/images/new-design/Maskgroup(1).svg");
  position: absolute;
  width: 100%;
  top: 0;
}

.pagination .btn.active {
  color: #ffffff;
  border-radius: 8px;
  background: #00A6A6;
}

.pagination {
  margin-bottom: 10px;
}

.btn.t {
  color: #4d4d4d;
}

.btn.t:hover,
a.btn.t:hover {
  color: #4d4d4d;
}

// rametron page css
.ram12 {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
  cursor: pointer;
}

.gtr51 .active {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #00A6A6;
  background: #00A6A6;
  cursor: pointer;
  color: #fff !important;
}

.ram12 p,
.gtr51 .active {
  color: #00A6A6;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.ram12 img,
.gtr51 .active img {
  border-radius: 10px;
}

.rtlist.gtr51 {
  justify-content: space-between;
  margin-bottom: -63px;
  position: relative;
  z-index: 99;
  margin-top: 0px;
  margin-right: 15px;
}

.ramotbs ul.nav.nav-pills {
  justify-content: end;
  background: #bbf6f4;
  padding: 10px;
  border-radius: 8px;
}

.ramoverr {
  border-radius: 8px 8px 0px 0px;
  background: #C3F3F3;
  padding-top: 50px;
}

.netwrokmap {
  border-radius: 0px 0px 8px 8px;
  background: #C3F3F3;
  padding: 40px;
}

.ramotbs .nav-item {
  margin: 0px 8px;
}

.ramotbs .nav-pills .nav-link {
  border-radius: 11px;
  border: 1px solid #B5D2D2;
  background: #DAF1F1;
  color: #639393;
}

.ramotbs .nav-pills .nav-link.active {
  border-radius: 11px;
  background: #00A6A6;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
}

.token.dashboard .column.gty90 .detail {
  background: none;
  box-shadow: none;
  padding: 0px;
  height: auto;
  margin: 0;
}

.token.dashboard .column .fhtt .detail .title {
  color: #4E4E4E;
  font-weight: 500;
}

.token.dashboard .column .fhtt .detail .value {
  font-size: 20px;
}

.ramoverr .token.dashboard {
  padding: 0px 30px;
}

.fhtt {
  display: flex;
}

.gtdeh {
  align-self: center;
}

.fdeea {
  padding-left: 15px;
}

.fhtt img {
  padding-right: 10px;
}

// public node page css
.csde3 {
  border-radius: 8px;
  background: #C3F3F3;
  padding: 40px;
}

.csde3 table.data.block-table thead th p {
  background: none;
  box-shadow: none;
  color: #000;
  text-transform: capitalize;
  padding: 10px 5px;
}

.csde3 table.data.block-table thead th {
  background: #fff;
  text-transform: capitalize;
}

.csde3 .members,
.csde3 .uptime {
  color: #000 !important;
}

.csde3 .table-responsive {
  box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.content.home1 {
  margin-top: 0px !important;
  padding-top: 160px;
}

// blocks page css
.content.blocks,
.content.transactions,
.content.stakes,
.content.block-details,
.content.account,
.content.transaction-details {
  padding-top: 125px;
  margin-top: 0px !important;
}

.browss {
  background-image: url("./../../public/images/new-design/Group 1000000937.svg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  background-position: center;
}

.fdr4 {
  padding: 78px 40px;
}

.de1 a button {
  border-radius: 23px;
  border: 1px solid #FFF;
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.48);
  padding: 10px;
  margin: 10px;
  color: #fff;
  background: #ff000000;
  width: 200px;
}

.de1 a button.mant3 {
  background: linear-gradient(136deg, #FF00B8 0%, #00FF85 100%);
  border: 0px;
}

// transition page css
.txt-de2.mnet6 table.data.txn-table2 thead tr {
  background-color: #fff;
}

.txt-de2.mnet6 table.data.txn-table2 thead th p {
  background: none;
  box-shadow: none;
  padding: 10px 0px;
  color: #000;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 500;
}

.mnet6 .forcpoy {
  display: flex;
}

.mnet6 table.data tbody td.overflow img {
  /* display: inline-block; */
  width: 21px;
  height: 7px;
  align-self: center;
  margin-right: 4px;
  cursor: pointer;
}

.mnet6 table.data tbody td,
.mnet6 table.data tbody th {
  color: #000000;
}

.br-rel {
  color: #000;
  font-size: 20px;
  font-weight: 500;
}

// top node page css
.container12 .nav {
  justify-content: center;
}

.container12 .nav-pills .nav-link {
  position: relative;
  border-radius: 14px;
  background: #FFF;

  box-shadow: 0px 2px 51px 0px rgba(0, 0, 0, 0.31);
}

.container12 .nav-link img {

  width: 100%;
  object-fit: contain;
  height: 100%;
}

.container12 .nav-link img.activ13 {
  display: none;
}

.container12 .column {
  position: absolute;
  z-index: 99;
  top: 38%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.container12 .nav-pills .zytrron .nav-link.active {
  border-radius: 14px;
  border: 4px solid #FB01C4;
  background: #262626;
  box-shadow: 0px 2px 51px 0px rgba(0, 0, 0, 0.31);
}

.container12 .nav-pills .zytrron .nav-link.active img.activ13 {
  display: inherit;
}

.container12 .nav-pills .zytrron .nav-link.active img.activ12 {
  display: none;
}

.container12 .nav-pills .metaro .nav-link.active {
  border-radius: 14px;
  border: 4px solid #00A6A6;
  background: #262626;
  box-shadow: 0px 2px 51px 0px rgba(0, 0, 0, 0.31);
}

.container12 .nav-pills .metaro .nav-link.active img.activ13 {
  display: inherit;
}

.container12 .nav-pills .metaro .nav-link.active img.activ12 {
  display: none;
}

.container12 .nav-pills .remaa .nav-link.active {
  border-radius: 14px;
  border: 4px solid #AD00FF;
  background: #262626;
  box-shadow: 0px 2px 51px 0px rgba(0, 0, 0, 0.31);
}

.container12 .nav-pills .remaa .nav-link.active img.activ13 {
  display: inherit;
}

.container12 .nav-pills .remaa .nav-link.active img.activ12 {
  display: none;
}

.container12 li.nav-item {
  margin: 241px 40px 0px;
  z-index: 99;
  width: 250px;
  height: 220px;
}

.stakes .container12 .title,
.rant6 {
  font-size: 16px;
  color: #8E9299;
  text-transform: uppercase;
}

.container12 .value {
  font-size: 16px;
  color: #8E9299;
  text-transform: uppercase;
}

.stakes .container12 .active .title,
.container12 .active .value,
.active .rant6 {
  color: #fff;
}

.stakes.trnaj .txt-de2 {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.25);
}

.stake-tb .address a {
  color: #00A6A6;
}

.stakes.trnaj table.data.txn-table2 thead th p {
  background: none;
  box-shadow: none;
  padding: 10px 0px;
  color: #000;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 500;
}

.stakes.trnaj table.data tbody td {
  color: #000000;
}

.stakes.trnaj .dotee3 {
  padding-right: 10px;
  width: 32px;
  height: 8px;
  align-self: center;
  cursor: pointer;
}

.container12 .tab-content {
  margin-top: 40px;
  position: initial !important;
}

.container12 .tab-content>.tab-pane {
  position: initial !important;
}

.container12 td.empty {
  display: none;
}

.container12 table.data tbody tr {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1607843137) !important;
}

.container12 .fre4 table.data tbody tr {
  border-bottom: 2px solid rgba(255, 255, 255, 0.1607843137) !important;
}

.stakes.trnaj .txt-de2.total76 {
  border-radius: 9px;
  background: #D9D9D9;
}

.total76 .gsrf p:first-child {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0;
}

.total76 .gsrf .ptx {
  font-size: 18px;
  font-weight: 700;
  color: #000;
  text-align: right;
  margin: 0;
}

.total76 .gsrf {
  width: 100% !important;
}

// Stakes page css
.stak23 .demgraph.newdemo {
  width: 88%;
  margin: 0 auto;
  margin-top: -45px;
}

.stak-chart {
  border-radius: 14px;
  background: #FFF;
  box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.22);
  padding: 20px;
  margin-top: -80px !important;
  // height: 100%;
  height: calc(100% + 80px);
  display: flex;
  align-items: center;
}

#app-content .content .stak-chart .page-title.stakes {
  margin: 0px;
  color: #000;
  font-size: 18px;
  letter-spacing: 0px;
  font-weight: 500;
}

// progress section
.progress {
  width: 70px;
  height: 70px !important;
  float: left;
  line-height: 150px;
  background: none;
  margin: 20px;
  box-shadow: none;
  position: relative;
  margin-bottom: 10px;
}

.small-pro {
  display: flex;
  justify-content: center;
}

.progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid #ddd;
  position: absolute;
  top: 0;
  left: 0;
}

.progress>span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progress .progress-left {
  left: 0;
}

.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 4px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.progress .progress-right {
  right: 0;
}

.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
  animation: loading-1 1.8s linear forwards;
}

.progress .progress-value {
  width: 90%;
  height: 100%;
  border-radius: 50%;
  background: #ffffff;
  font-size: 12px;
  color: #8E9299;
  line-height: 135px;
  text-align: center;
  position: absolute;
  font-weight: 500;
  top: -31px;
  left: 5%;
  bottom: 0;
}

.progress.blue .progress-bar {
  border-color: #049dff;
}

.progress.blue .progress-left .progress-bar {
  animation: loading-2 1.5s linear forwards 1.8s;
}

.progress.yellow .progress-bar {
  border-color: #fdba04;
}

.progress.yellow .progress-right .progress-bar {
  animation: loading-3 1.8s linear forwards;
}

.progress.yellow .progress-left .progress-bar {
  animation: none;
}

.ur7 h5 {
  font-size: 16px;
  color: #000;
  text-transform: capitalize;
}

.viewmore2 {
  width: 88%;
  margin: 0 auto;
  padding-top: 10px;
}

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(144deg);
    transform: rotate(144deg);
  }
}

@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
}

.viewmore2 p {
  color: #00A6A6;
  font-size: 14px;
  text-align: right;
}

.re40 {
  width: 85%;
  margin: 0 auto;
}

.re40 td.empty {
  display: none;
}

.re40 .arrow-container {
  text-align: right !important;
  color: #00A6A6 !important;
}

.re40 tr.totalstk34 td {
  // border-radius: 9px;
  background: #D9D9D9;
}

.footer {
  background: #EDFFFF;
  padding: 50px 160px 10px;
}

.input-field a {
  color: #000;
}

.feera p {
  color: #343434;
  font-size: 16px;
  line-height: 28px;
  margin: 20px 0px;
}

.linkss a img {
  margin-right: 10px;
  transition: transform .2s;
  width: 25px;
  height: 25px;
  object-fit: contain;
}

.linkss a img:hover {
  transform: scale(1.5);
}

.fomm form {
  width: 100%;
}

.fomm .form-group {
  display: flex;
  width: 100%;
  background: #fff;
  padding: 9px;
  border-radius: 10px;
}

.footer-link li {
  color: #000;
  list-style: none;
  padding-bottom: 15px;
  font-size: 15px;
}

.footer-link ul {
  padding: 0;
}

.footer-link h4 {
  color: #0E0F1D;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}

.footer-link p {
  color: #0E0F1D;
  font-size: 14px;
  margin: 10px 0px;
}

.cpyy p {
  color: #000;
  text-align: center;
  font-size: 16px;
  margin-bottom: 0px;
}

.cpyy {
  padding: 10px;
  border-top: 1px solid #00A6A6;
  margin-top: 30px;
}

.fomm button {
  border: 0;
  background: none;
}

.topsrc {
  display: inline-block;
  background: linear-gradient(90deg, #56DF9E -15.19%, #c86cfe 37%, #C86CFE 8%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 24px;
  font-weight: 700;
}

.cduj98-gif {
  width: 100%;
  text-align: center;
}

.cduj98-gif img {
  width: 235px;
  mix-blend-mode: screen;
  position: absolute;
  top: -77px;
  right: 0;
}

.gif09
{
  text-align: center;
}
.searchContainer.tdcg6 {
  margin: inherit;
  width: 100%;
  align-items: center;
  text-align: CENTER;
  justify-content: center;
}

.searchContainer.tdcg6 select {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: none;
  border-radius: 13px 0px 0px 10px;
  color: #000;
  padding: 12px;
}

.tdcg6 .searchiconwrapper svg {
  background: #00A6A6;
  border-radius: 0px 10px 10px 0px;
}

.tdcg6 .search-input.nwe1 {
  background: #fff;
  box-shadow: none;
  border: 1px solid #000;
  border-radius: 0px 12px 12px 0px;
  padding-right: 50px;
  color: #000;
  font-size: 14px;
  border-left: 0;
}

.search-input.nwe1::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #848181;
  opacity: 1;
  /* Firefox */
}

.top-imga {
  width: 100%;
  position: absolute;
  top: 0;
}

.top-imga img {
  width: 100%;
  height: 350px;
}

.searchContainer.tdcg6 select {
  -webkit-appearance: none;
  -moz-appearance: window;
  background-image: url("./../../public/images/new-design/Vectoraa.svg");
  background-repeat: no-repeat;
  background-position: 91%;
  background-size: 12px;
}

// details page 
// block
#app-content .content.block-details .page-title {
  color: #000;
  text-align: left;
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0px;
  text-transform: capitalize;
  padding-left: 30px;
}

.block-details .txt-de2 {
  border-radius: 22px;
  background: #EFEFEF;
  box-shadow: none;
}

.block-details table.details tbody tr th {
  color: #000000 !important;
  padding-bottom: 0px;
  text-transform: capitalize;
}

.content.transaction-details table.details tbody tr td {
  color: #000;
  // padding-bottom: 0px;
  border: 0;
  // padding: 0
}

.block-details table.details tbody tr td {
  color: #4E4E4E;
  border-bottom: 0px solid #42455e;
  padding-bottom: 5px;
}

.block-details table.details tbody tr td a {
  color: #4E4E4E;
}

p.finaly {
  margin: 0px;
  border-radius: 16px;
  background: #D2FFCF;
  padding: 1px 8px;
  font-size: 15px;
  width: fit-content;
}

.content.transaction-details .hrt5 p,
.content.block-details .hrt5 p {
  display: inline-block;
  position: relative;
  height: 26px;
  line-height: 29px;
  text-align: center;
  padding: 0 40px 0 18px;
  font-size: 14px;
  background: #00A6A6;
  color: #fff;
  box-sizing: border-box;
}

.content.transaction-details .hrt5 p::after,
.content.block-details .hrt5 p::after {
  position: absolute;
  content: "";
  width: 0px;
  height: 0px;
  z-index: 1;
  top: 0;
  right: 0;
  border-width: 14px 10px 10px 0px;
  border-color: #00A6A6 #efefef #00A6A6 #5a3939;
  border-style: solid;
}

.content.block-details .txt-de2.nh6 .block-txn .txn-type p {
  padding-left: 10px;
  color: #fff;
  text-transform: capitalize;
}

.txt-de2.nh6 {
  padding: 5px 0px;
}

.txt-de2.nh6 .block-txn .txn-type {
  padding-left: 0;
}

.txt-de2.nh6 .block-txn .txn-type p {
  margin-left: 0;
}

img.dotimgg.pr-2 {
  margin-top: -10px;
}

.content.block-details table.transactions td.hash a {
  display: inline-block;
}

.searchContainer.tdcg6.forcent {
  margin: 0 auto;
  text-align: center;
  width: 60%;
}

table.data tbody tr.block-txn:hover td {
  background-color: rgba(240, 240, 255, 0.05);
}

.nbcd978 {
  position: relative;
  width: 85%;
  margin: 0 auto;
}

.for-mob24 {
  display: none;
}

.nbcd978 .button-list.split {
  position: absolute;
  width: 100%;
  top: 0px;
  margin-top: 19px;
}

.nbcd978 .txt-de2.nh6 {
  width: 92%;
  margin: 0 auto;
}

.content.block-details h3 {
  color: #000;
  text-align: left;
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0px;
  text-transform: capitalize;
  padding-left: 30px;
  width: 76%;
  margin: 0 auto;
  margin: 40px auto 25px;
}

// ACCOUNT DETAIL pages
.account .txt-de2 {
  border-radius: 22px;
  background: #EFEFEF;
  box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.25);
  // background: #FFF;
}

.account table.details tbody tr th {
  color: #000 !important;
  text-transform: capitalize;
  padding-bottom: 0px;
  padding: 0 15px;
}

.account table.details thead tr th {
  font-family: 'Montserrat', sans-serif;
  padding-bottom: 0px;
  text-transform: capitalize;
  border: 0;
}

.account table.details tbody tr td {
  color: #4E4E4E;
  border-bottom: 0px solid #42455e;
  padding-bottom: 5px;
}

#app-content .content.account .page-title {
  margin-bottom: 10px;
  color: #000;
  text-align: left;
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0px;
  text-transform: capitalize;
}

.he341 h4 {
  color: #000;
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  display: inline-block;
  width: 20%;
}

.he341 h6 {
  color: #656565;
  font-size: 18px;
  font-weight: 400;
  display: inline-block;
  width: 80%;
  text-align: right;
}

.account table.data.txn-table2 thead th p {
  background: none;
  box-shadow: none;
  color: #000;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 500;
  margin-top: 0px;
  padding: 0;
}

.account .bott7 table.txn-table2 tr td {
  background: #efefef;
  color: #4E4E4E;
}

.account .hbd5 {
  color: #000;
  text-transform: capitalize;
}

.account .bott7 table.txn-table2 tr td {
  border: 0px;
}

.stakes td.arrow-container {
  color: #00A6A6 !important;
  text-align: right !important;
  padding: 0px 0px 7px !important;
}

.stakes table tr td.arrow-container {
  padding: 10px !important;
}

// ===============================






.faq-section .mb-0>a {
  display: block;
  position: relative;
}

.faq-section .mb-0>a:after {
  content: "View more";
  color: #00A6A6;
  position: absolute;
  right: 0;
  font-weight: 600;
}

.faq-section .mb-0>a[aria-expanded="true"]:after {
  content: "View less";
  color: #00A6A6;
  font-weight: 600;
}

.staknhkr {
  width: 88%;
  margin: 0 auto;
}

// .collapse.forheight{

// }
.forheight.collapse:not(.show) {
  display: block;

}

.forheight.collapse.show .backgr {
  height: auto;
}

.stakesr44 {
  width: 80%;
  margin: 0 auto;
}

// ===============================


.vie333 td {
  background-color: #D9D9D9 !important;
}

.account .juh6 p {
  text-align: left !important;
  background: none;
  box-shadow: none;
  font-size: 22px;
  text-align: center;
  letter-spacing: 0px;
  width: 100%;
  margin-bottom: 30px;
  text-transform: capitalize;
  padding: 10px 0px;
  border-radius: 33px;
  font-weight: 500;
  margin-left: 0;
  color: #000;
  margin-bottom: 0px;
}

.account .customtansaciton button {
  width: 225px;
  border-radius: 10px;
  background: #D9D9D9;
  color: #727272;
  font-weight: 500;
  text-transform: capitalize;
}

.tranctab {
  border-radius: 22px;
  background: #EFEFEF;
}

.account .customtansaciton {
  width: 100%;
  text-align: center;
  padding-top: 25px;
}

.content.account .actions {
  padding-top: 2rem;
  text-align: center;
  width: 100%;
  display: block;
}

.content.account .actions .btn.tx {
  font-size: 0.875rem;
  font-weight: 400;
  color: #01aca7;
}

.content.account .mnet6 {
  margin-top: 20px;
}

.content.account .custom-btn.trans-54 {
  background: none;
  box-shadow: none;
}

.cxcxcx p {
  display: inline-block;
  position: relative;
  height: 26px;
  line-height: 29px;
  text-align: center;
  padding: 0 40px 0 18px;
  font-size: 14px;
  background: #00A6A6;
  color: #fff;
  box-sizing: border-box;
  margin-left: 0px !important;
  margin: 0;

}

.cxcxcx p::after {
  position: absolute;
  content: "";
  width: 0px;
  height: 0px;
  z-index: 1;
  top: 0;
  right: 0;
  border-width: 14px 10px 10px 0px;
  border-color: #00A6A6 #ffffff #00A6A6 #5a3939;
  border-style: solid;
}

.mnet6 table.data tbody td.cxcxcx {
  padding-left: 0px !important;

}

// transitions details
.content.transaction-details .txt-de2 {
  border-radius: 22px;
  background: #EFEFEF;
  box-shadow: none;
}

.content.transaction-details .txt-de2.tranbdee {
  padding-left: 30px;
}

.lop9 a {
  color: #4E4E4E;
}

.content.transaction-details .txt-de2 {
  padding: 10px 0px;
}

.content.account .bgtd7 p {
  // margin: 0px
}

.ac-det.transfre4 {
  padding-left: 35px;
}

.content.transaction-details table.details tbody tr th.hrt5 {
  padding-left: 0px;
}

.content.transaction-details table.details tbody tr th p {
  margin: 0px;
}

.cdsew31 a {
  color: #4E4E4E;
}

.content.transaction-details table.details {
  border-radius: 22px;
  background: #EFEFEF;
  box-shadow: none;
}

.linkss a.twit img {
  width: 15px;
}

.formob91 {
  display: none;
}

.formob91 .dropdown {
  float: inherit;
}

.formob91 .navbar-light.new-hede .test-nt {
  text-align: center;
}

.formob91 .navbar-light.new-hede .test-nt a {
  color: #c1bdbd;
}

// arrow button
.btn.icon.next:hover i,
a.btn.icon.next:hover i {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA4klEQVR4nO3Z0QnCQBBF0acVWIotWIhoIQFZ8N8SRLaQtGApliALyZ9fQmYezD0FuMyNq5tEAAAAAAAAwLam1h9T60eXzLvIxabWn5Kukj6STvfb+R25/i/7qIXGlV+GHw6SZodvQlgASa/lyq8sIkRvgTHsvAy/St0OoQFkGCE8gMwipASQUYS0ADKJkBpABhHSAyg5gkUAJUawCaCkCFYBlBAh8ihsiS2wxYf+o/SPYOm/wdIHodJH4dI3Q6Vvh0s/ECn9SMxx+CHyKHxxG34o/2IknNurMQAAAAAAAJQi6Qt9pplLc0aHvAAAAABJRU5ErkJggg==) !important;
}

.btn.icon.last:hover i,
a.btn.icon.last:hover i {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAABYlAAAWJQFJUiTwAAABLklEQVR4nO3YwUkEQRBG4acYgKEYghPIombg3geWgb1rBioTyBiCoWwIUtALE0BVUaX/d+tLL/W6mYVGREREREREROS/uekw77ysG/A4lt/n02Hy2vvWa6O9eVnf5mV9iNjbm3uAeVk/gFdg6xDBNYCdPPA8lvcdInjfgC/gsluXj+Aa4Hw6/ABTpwju34BuEUL+BTpFCAlAowhhAWgSITQADSKEB6B4hJQAFI6QFoCiEVIDUDBCeoBq0gOMU97GqV/ZbZjG7UiVGqDa8CYtQMXhTUqAqsOb8ACVhzehAaoPb8ICdBjeRL0KtxjeRLwKtxneeL8KtxreeN+Ap07DG+9X4SPwOZblhzcRr8IvwHuH4c1dxKbjJrQQEiDAcfdtuaT+soiIiIiIiIj8LcAv2JKhg8pJD2oAAAAASUVORK5CYII=) !important;
}

.btn.icon.prev:hover i,
a.btn.icon.prev:hover i {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA/klEQVR4nO3ZwQnCQBCF4VEbSClWIKQF70HsQwiCd1tQUoBnT4oVWIolyEDmIuptZyfM/xWQ2fdCkk0iAAAAAAAAQB27/bDc7Yej9/BZhPOt4UXkJiKNiJwOfbf1mr3wGvTLR3i1XLXr5nG/XD3mzz2G/PIlvHqJyNlrDdUugT/h20PfPb3WUaWAKOGVewGRwivXAqKFV24FRAyvXAqIGl4VLyByeFW0gOjhVbECphBeFSlgKuFV1a1wBFwCJQ+e+iZoUj8GTeqNkEm9FTapX4ZM6tdhk/qDiEn9ScxEKKHqVngM2Y6hjZax8VpD+h8jYdT6NQYAAAAAAIBUROQNJD+YuLdGDRwAAAAASUVORK5CYII=) !important;
}

.btn.icon.first:hover i,
a.btn.icon.first:hover i {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAABYlAAAWJQFJUiTwAAABOklEQVR4nO3X0QnCMBQF0Kc4gKM4gh2kqBvofyEE+q8bqHSQOoKjdAR5EKEfpf15ybviPX9KSby3sU2EiIiIiIiIiOjfrCzzNrHrRWSfPr7aUFfofa4BfsOiJna7JnbXHGPDF6DhRURX1rmJ3d16fOgCRuG36auj9UqALWAivBpE5Gk5D2QBM+GrNtRvy7ngCigZXkEVUDq8ginAI7yCKMArvHIvwDO8ci3AO7xyKwAhvHIpACW8Kl4AUnj1E6fBnIoXkO5yle76l66GPq2OolxWAFIJbn8BlBJcnwEIJbg/BL1LgHgLeJYA8xr0KgFqH+BRAtxGqHQJkDvBkiXAboVnSjhYzgN9Fpgo4dGG+mI5B/xhaFTCrQ31yXr8jfWAOaQSshyVrQu4jM75w8K1RERERERERETlicgHWeiisqCiH9AAAAAASUVORK5CYII=) !important;
}

// spiner
.spinner,
.spinner .m {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  border-width: 3px !important;
}

.spinner {
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  transform: translateZ(0);
  animation: SpinnerAnimation 1.1s infinite linear;
  border-radius: 50%;
  border-left-color: #00c88b;
  border-bottom-color: #00c88b54;
  border-right-color: #00c88b54;
  border-top-color: #00c88b54;
}

.leaflet-right {
  right: 20px !important;
}

.none.icon {
  display: none !important;

}

@media only screen and (max-width: 1300px) {
  .cduj98-gif img {
    top: -145px;
  }
}

@media (max-width : 1200px) {
  .container12 li.nav-item {
    margin: 229px 6px 40px;
  }
}

@media (max-width : 1150px) {
  .content.account .actions {
    display: block;
  }
}

@media (max-width: 1024px) {
  .logo {
    margin-left: 5px;
    margin-right: 10px;
    // width: 50%;
  }

  .dd-button {
    width: auto;
  }



  .data.block-table,
  .data.txn-table2,
  .details.txn-details,
  .details.txn-info,
  .txn-info.details,
  .data.transactions,
  .details.account-info,
  .data.txn-table2 {
    display: table;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
  }

  .overview.tab-bg .data.block-table,
  .overview.tab-bg .data.txn-table2 {
    display: table;
  }

  .table.details thead tr th:first-child {
    width: auto;
  }

  .stakes .title {
    font-size: 16px;
  }

  .content.account .actions .download {
    display: none;
    width: auto;
    height: auto;
    opacity: 1;
    visibility: inherit;
  }

  .content.account .actions {
    text-align: center;
    margin-bottom: 20px;
    /* font-size: 20px; */
    display: block;
  }

  .customtansaciton {
    display: inline-block;
    width: 100%;
  }

  .content.account .actions .btn.tx {
    font-size: 16px;
  }

  .nod-st .row {
    width: 100%;
    padding: 30px 10px;
  }

  .ip09 .ip-input {
    width: 100%;
    height: 50px;
  }

  .nodeStatusDisclaimer {
    width: 100%;
  }
}


@media (max-width : 991px) {

  .ram12 img,
  .gtr51 .active img {
    border-radius: 10px;
    width: 82px;
    margin: 0 auto;
    text-align: center;
  }

  .logo {
    height: 50px;
  }

  .walletana {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin: 10px 0px;
  }

  .nonmobile {
    display: none !important;
  }

  .cxcxcx p {
    padding: 0 15px;
  }

  .container12 {
    margin-top: 70px;
  }

  .stak23 .demgraph.newdemo {
    margin-top: -45px !important;
  }

  .stak-chart {
    margin-top: 0px !important;
    height: auto;
  }

  .re40 {
    width: 100%;
  }

  .forweb90 {
    display: none;
  }

  .formob91 {
    display: block;
  }

  .stakes .container12 .title,
  .rant6 {
    font-size: 12px;
    color: #8E9299;
    // width: 54%;
    text-transform: uppercase;
  }

  .ram12 {
    text-align: center;
  }

  .ram12 p,
  .gtr51 .active {
    font-size: 14px;
    font-weight: 600;
  }

  .ram12 img,
  .gtr51 .active img {
    border-radius: 10px;
    width: 82px;
    margin: 0 auto;
  }

  .container12 .nav {
    // justify-content: inherit;
    margin: -70px 0px;
    margin-bottom: 0;
  }

  .container12 .nav-pills .nav-link {
    margin-bottom: 10px;
  }

  // .container12 li.nav-item {
  //   margin: 0px 0px;
  // }
  .form-inline.firu6 {
    display: block;
  }

  .pando-head li.nav-item {
    text-align: left;
    border-bottom: 1px solid rgba(204, 204, 204, 0.164);

    p {
      display: inline-block;
    }

    a {
      display: block;
      text-align: left;
    }
  }

  .dappsonpando .wrpas div {
    height: auto !important;
  }

  .dappsonpando {
    display: block !important;
  }

  .dappsonpando .wrpas {
    padding: 10px !important;
    width: 100% !important;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    height: auto !important;
  }

  .token.dashboard .column {
    width: 50% !important;
  }

  .dd-button.search-select select {
    margin-left: 0px;
  }

  .downloadContainer {
    padding: 0px;
  }

  .download-page-network .btn-flex button {
    width: 100%
  }

  .search-input.nwe1 {
    width: 100%;
  }

  .searchiconwrapper {
    position: relative;
    width: 100%;
  }

  // .searchContainer select{
  //   margin-left: 10px;
  // }
  .nodedetails div {
    width: 100% !important;
    margin: 15px;
  }

  .nodedetails {
    display: block !important;
  }

  .navbar-light.new-hede .test-nt a {
    color: #fff;
  }

  .navbar-light.new-hede .test-nt {
    text-align: center;
  }
}

@media (max-width : 991px) {
  .overview.tab-bg {
    padding: 0px 0px;
  }

  .juh6 p .custom-btn.trans-54 img {
    width: 23px;
    margin-top: -6px;
  }

  .account .bott7 table.txn-table2 tr td {
    padding: 10px !important;
  }

  .account table.data.txn-table2 thead th p {
    font-size: 14px;
  }

  .stake-tb .token .currency {
    float: none;
  }

  .content.account .bgtd7 p,
  .txt-de2.mnet6 table.data.txn-table2 thead th p {
    font-size: 14px;
  }

  .content.transaction-details .details-header .txn-type {
    padding: 0;
  }

  .content.transaction-details .txt-de2.tranbdee {
    padding-left: 0;
  }

  table.details tbody tr th {
    padding: 5px;
  }

  p {
    font-size: 14px;
  }

  .details.txn-info tbody tr th,
  .details.txn-info tbody tr td,
  .details.txn-info th,
  .details.account-info th,
  .details.account-info td {
    padding: 15px;
  }

  table.details tbody tr td {
    padding: 0
  }

  table.txn-table2 tr td {
    padding: 10px !important;
  }

  .topsrc {
    font-size: 20px;
  }

  #ostrich .title.mt-1.gsrf {
    padding: 10px;
  }

  #ostrich .total76 .gsrf .ptx {
    font-size: 16px;
    font-weight: 500;
  }

  #ostrich .total76 .gsrf p:first-child {
    color: #000;
    font-size: 16px;
  }

  .container12 li.nav-item {
    margin: 140px 6px 0px;
    // width: calc(30% - 6px);
    height: auto;
  }

  .nbcd978 .txt-de2.nh6 {
    width: 100%;
  }

  .for-web23 {
    display: none;
  }

  .for-mob24 {
    display: block;
    text-align: center;
  }

  .nbcd978 {
    width: 100%;
  }

  .for-mob24 .btn.icon,
  a.btn.icon {
    display: inline-block;
    height: auto;
    min-width: auto;
    margin: 0px 5px;
  }

  .nbcd978 .button-list.split.for-mob24 {
    position: relative;
  }

  .legends span {
    margin-right: 7px;
    padding-left: 16px;
    font-size: 10px;
  }

  .search-input.nwe1::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 12px;
  }

  .legends span:after {
    width: 7px;
    height: 7px;
    top: 4px;
  }

  .cduj98-gif img {
    display: none;
  }

  .csde3 {
    padding: 15px;
  }

  .cpyy p {
    font-size: 12px;
  }

  .browss {
    text-align: center;
  }

  .fdr4 {
    padding: 20px;
  }

  .content.transactions .mnet6 {
    margin-top: -82px !important;
  }

  .content.transactions table.data.txn-table2 thead th {
    padding: 10px;
  }

  .backgr {
    width: 95%;
  }

  .new-hede.fixed-top {
    background: #000;
    padding: 0px 15px;
  }

  .th-not-exist p {
    line-height: 49px;
    font-size: 30px;
  }

  .stakes.trnaj table.data.txn-table2 thead th p {
    padding: 10px 10px;
    color: #000;
    font-size: 16px;
  }

  .token.dashboard .column .fhtt .detail .value {
    font-size: 16px;
  }

  .footer {
    background: #EDFFFF;
    padding: 30px 15px 10px;
  }

  .tbal-bl2,
  .tbal-ty2 {
    padding-right: 0px;
    margin-top: 10px;
  }

  .rtlist.gtr51 {
    display: flex;
    align-items: center;
    overflow: auto;
    white-space: nowrap;
  }

  .ram12,
  .gtr51 .active {
    margin-right: 10px;
  }

  .t1 {
    width: 100%;
  }

  .explore-1 h2 {
    font-size: 30px;
  }

  .token.dashboard .column {
    width: 100% !important;
  }

  .explore-1 h3 {
    font-size: 35px;
  }

  .searchContainer {
    display: flex;
    width: 100%;
    margin: 0 auto;
  }

  .searchContainer select {
    padding: 13px 20px 13px 10px;
    font-size: 14px;
    width: 126px;
  }

  .chart-container {
    height: auto;
  }

  .content.stakes .chart {
    width: 100%;
    margin: 0 0%;
    margin-bottom: 15px;
  }

  .explore-1 {
    padding-bottom: 16px;
  }

  .pando-head .nav-item>a {
    padding: 5px;
  }

  .select__container {
    display: block;
    margin: 15px 0;
  }

  .juh6 p {
    font-size: 14px;
    padding: 15px;
  }

  .content.account .actions {
    text-align: center;
    width: 100%;
  }

  .customtansaciton {
    text-align: center;
    display: flex;
    overflow: auto;
  }

  .content.account .customtansaciton button {
    white-space: nowrap;
  }

  .fhg54-tb .react-tabs__tab-list {
    text-align: center;
  }

  .fhg54-tb .react-tabs__tab-list .react-tabs__tab {
    margin-bottom: 10px;
  }

  .laijdy6 a {
    display: inline-block;
  }

  .wlladd {
    display: inline-block !important;
    width: 118px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.875rem;
    vertical-align: inherit;
    margin: 0;
  }

  .do-bth {
    width: 100%;
    text-align: center;
  }

  .do-bth .rametronDownloads {
    width: auto;
    display: block;
    margin-right: 0px;
  }

  .do-bth .nav-search {
    width: 100%;
  }

  .whsts h1 {
    font-size: 26px;
    height: auto;
    padding: 80px 0px 100px;
  }

  .netwrokmap {
    padding: 6px;
  }

  .download-page-network .btn-flex {
    display: block !important;
    justify-content: space-evenly;
  }

  .download-page-network .btn-flex button {
    margin-bottom: 10px;
  }

  .account-info th,
  .account-info td,
  table.details tbody tr th {
    font-size: 14px !important;
  }

  .download-page-network .btn-flex button {
    width: 100% !important;
  }

  .dappsonpando .wrpas {
    width: 100% !important;
    padding: 5px;
    text-align: center;
  }

  .dappsonpando {
    margin: 10px;
    padding: 10px 0px;
    display: block !important;
  }

  .viewmore {
    margin-bottom: 30px !important;
  }

  .demgraph {
    margin-top: 30px !important;
  }

  .download {
    display: inline !important;
  }

  .searchContainer.tdcg6 select,
  .tdcg6 .search-input.nwe1 {
    border-radius: 13px;
  }

  .tdcg6 .search-input.nwe1 {
    margin-top: 5px;
    border-left: 1px solid #000;
  }

  .searchiconwrapper svg {
    right: 0px;
    top: 5px;
  }

  .all-in1 {
    padding: 10px;
  }

  .rant5 p {
    font-size: 16px;
  }

  .add {
    // display: block !important;
    max-width: 50px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .cc {
    top: 30px;
  }

  .pando-header {
    display: block;
  }

  //   .gif09 img {
  //     width: 300px;
  //     mix-blend-mode: screen;
  // }
  .pando-header .nav,
  .pando-header .nav-search {
    position: relative;
    top: 0px;
  }

  .pando-header .nav {
    height: 333px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    top: -10px;
    text-transform: uppercase;
  }

  .pando-header .nav-search {
    margin: 0;
    top: 0px;
  }

  .pando-header .nav a.nav-item {
    display: flex;
    margin-right: 0;
    text-transform: uppercase;
  }

  .pando-header .nav p img {
    height: 25px;
    margin-top: -8px;
    margin-right: 10px;
  }

  .pando-header .nav-search .search-button {
    position: absolute;
    right: 10px;
    bottom: 21px;
  }

  .pando-header .nav-search .search-select {
    width: 80%;
    margin: 10px 0;
    background: transparent linear-gradient(180deg, #360046 0%, #004F51 100%) 0% 0% no-repeat padding-box;
  }

  .pando-header .nav-buttin:focus {
    outline: none;
  }

  .resource {
    float: left;
  }

  .dd-button {
    width: 100%;
    left: 0px;
    margin-bottom: 12px;
    right: 0px;
    margin-left: 0;
    margin-top: 10px;

  }

  .resource {
    float: left;
  }

  .custom {
    float: left;
  }

  .active-nav1 {
    right: 0px !important;
  }

  table.data.txn-table {
    top: 0px !important;
  }

  .custom-btn {
    left: -10px !important;
  }

  .total-rametron {
    top: 50px;
  }

  .custom-btn1 {
    width: 150px !important;
  }

  .download-right {
    padding: 10px;
    width: 100% !important;
  }

  .ram12 img,
  .gtr51 .active img {
    width: 70px;
    margin: 0 auto;
  }

}


@media screen and (max-width: 576px) {
  .download-page-network .img img {
    display: none;
  }

  .stakes .container12 .title,
  .rant6 {
    font-size: 10px;
    // width: 58%;
    text-transform: uppercase;
  }
}

@media (max-width: 500px) {
  .rtlist.gtr51 {
    justify-content: inherit;
  }

  .fhtt img {
    padding-right: 10px;
    align-self: center;
    margin-top: -14px;
  }

  .container12 .column {
    top: 20%;
  }

  .topsrc {
    font-size: 16px;
  }

  #colophon .col-lg-2 {
    width: 33%;
    margin-top: 20px;
  }

  .footer-link h4 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .footer-link li {
    font-size: 12px;
  }

  .footer-link p {
    font-size: 12px;
  }

  .input-field a {
    color: #000;
    font-size: 12px;
  }

  .ram12 p,
  .gtr51 .active p {
    font-size: 10px;
  }

  .container12 .nav {
    justify-content: inherit;
    align-items: center;
    overflow: auto;
    flex-wrap: inherit;
  }
}






.nav-downloadsitemss {
  padding: 2px 5px;
  display: block;
}

.remnodesasas {
  padding-left: 20px !important;
}

.tipicheadr {
  font-size: 24px;
  // margin-left: 20px;
  margin-bottom: -40px;
  margin-top: 50px;
  text-align: CENTER;
}

.forheight,
.forheight .backgr {
  min-height: 300px;

}

.account .juh6 p.ss5dsx {
  text-align: center !important;
}


// contract details

.account.contract-verification .ss5dsx {
  color: #818CF8;
  font-size: 32px;
  font-weight: 900;
}

#app-content .account.contract-verification {
  background-image: url("../../public/images/imgpsh_fullsize_anim (1).png");
  margin: 0;
  background-repeat: no-repeat;
  margin-bottom: -60px;
  padding-bottom: 100px;
  width: 100%;
}

#app-content .account.contract-verification .fhg54-tb {
  width: 90%;
  margin: 50px auto 0;
}

#app-content .account.contract-verification .react-tabs__tab.react-tabs__tab--selected,
#app-content .account.contract-verification .react-tabs__tab {
  background: linear-gradient(90deg, #4776E6 0%, #8E54E9 100%);
  box-shadow: 4px 6px 61px 0px #6368E733;
  border-radius: 25px;
  color: #F1F5F9;
  font-weight: 600;
  width: 220px;
}

#app-content .account.contract-verification .react-tabs__tab:first-child {
  background: linear-gradient(90deg, #848288 0%, #B3B3B3 100%);
  box-shadow: 4px 6px 61px 0px #6368E733;
  border-radius: 25px;
  color: #F1F5F9;
  font-weight: 600;
  width: 220px;
}

#app-content .account.contract-verification .smskfn {
  text-align: center;
  margin-bottom: 50px;
}

#app-content .account.contract-verification .actions {
  display: none;
}

#app-content .account.contract-verification .read-contract__wrapper {
  border-radius: 18px;
}

#app-content .account.contract-verification .read-contract__title {
  text-align: center;
  color: #fff;
}

#app-content .account.contract-verification .read-contract__content {
  padding: 0
}

.icnsicn p {
  color: #818CF8;
  font-size: 24px;
  font-weight: 600;
}

.icnsicn h5 {
  color: #fff;
  font-size: 16px;

}

.sredwer3 {
  display: none !important;
}

.topsnebar {
  display: inline-block !important;
  width: calc(20% - 20px);
  padding: 20px 10px;
  border-radius: 12px;
  background: linear-gradient(95.52deg, rgba(255, 255, 255, 0.0485) 12.56%, rgba(176, 114, 255, 0.05) 99.55%);
  box-shadow: 0px 0px 3px 1px rgb(161 78 223 / 78%);
  margin: 10px;
  // border-image-source: radial-gradient(48.6% 799.61% at 50% 50%, #4776E6 0%, rgba(142, 84, 233, 0) 100%);
  text-align: center;
}

.sedondf {
  margin-top: 10px;
}

.chandand3rxs {
  color: #fff;
  margin: 10px;
  display: inline-block;
  width: calc(50% - 20px);

  .chandleft {
    display: inline-block;
    padding: 20px 10px;
    background: rgba(255, 255, 255, 0.2);
    border-top-left-radius: 10px;
    width: 40%;
    border-bottom-left-radius: 10px;
    text-align: center;
  }

  .chandright {
    display: inline-block;
    padding: 20px 10px;
    background: rgba(255, 255, 255, 0.08);
    border-top-right-radius: 10px;
    width: 60%;
    font-size: 14px;
    color: #818CF8;
    border-bottom-right-radius: 10px;
  }
}

.chanthirdsec {
  color: #fff;
  border-bottom: 1px solid #666;

  .fsl {
    width: 25%;
    display: inline-block;
    padding: 10px;
    vertical-align: top;
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #666;

    position: relative;
    margin-bottom: 30px;
    padding-bottom: 30px;
  }

  .fsl:after {
    position: absolute;
    content: "";
    width: 50%;
    border: 1px solid rgb(0, 229, 218);
    right: 5px;
    top: 52px;
  }

  .fsl:before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid rgb(0, 229, 218);
    right: 0;
    top: 48px;
  }

  p {
    color: #ccc;
    font-size: 14px;
    margin: 0px;
    // margin-bottom: 5px;
  }

  .fslfts {
    color: #818CF8;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .fslfss {
    color: #ccc;
    font-size: 14px;
    margin-bottom: 0px;
  }

  .fslfst {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    margin-top: 0;
  }

  .fsl.fsf:after,
  .fsl.fsf:before {
    display: none;
  }
}

.thirsread {
  padding: 30px 0px;

  .thirdfs {
    width: 100%;
    display: block;
    margin-bottom: 15px;

    h5 {
      color: #818CF8;
      font-size: 18px;
      font-weight: 600;
      margin-right: 0px;
      display: inline-block;
      width: 20%;
      vertical-align: top;
      text-align: left;
    }

    p {
      color: #ccc;
      font-size: 14px;
      margin: 0;
      vertical-align: top;
      margin-left: 15px;
      width: calc(40% - 30px);
      display: inline-block;
      text-align: left;
    }
  }

}

.account.contract-verification .contract-info,
.account.contract-verification .contract-info__cell>div:nth-child(2),
.contract-info__title--sub {
  color: #fff;
}

.account.contract-verification .contract-info {
  border-radius: 18px;
}


@media(max-width: 1300px) {
  #app-content .account.contract-verification .fhg54-tb {
    width: 100%;
  }

  .icnsicn h5,
  .chandand3rxs .chandleft {
    font-size: 14px;
  }
}


@media(max-width: 1000px) {
  .topsnebar {
    width: calc(50% - 20px) !important;
  }

  .chandand3rxs {
    width: calc(100% - 20px) !important;
  }

  .chanthirdsec .fsl,
  .thirsread .thirdfs h5,
  .thirsread .thirdfs p {
    width: 100%;
  }

  .chanthirdsec .fsl:after {
    position: absolute;
    content: "";
    width: 1px;
    border: 1px solid rgb(0, 229, 218);
    left: auto;
    bottom: -80px;
    height: 49%;
    top: auto;
    right: auto;
    width: auto;
  }

  .chanthirdsec .fsl {
    border: none;
  }

  .chanthirdsec .fsl:before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-top: 5px solid rgb(0, 229, 218);
    border-bottom: 5px solid transparent;
    border-left: 5px solid transparent;
    left: auto;
    right: auto;
    top: auto;
    bottom: -88px;
    margin-left: -4px;
    border-right: 5px solid transparent;
  }

  .thirsread .thirdfs {
    margin-bottom: 40px;
  }

  .chanthirdsec {
    text-align: center;
  }
}


@media(max-width: 767px) {

  .topsnebar {
    width: 100% !important;
  }
}
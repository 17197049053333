
.th-not-exist{
    height: calc(50% - 90px);
    width: 80%;
    margin-left: 10%;
    text-align: center;
    font-size: 40px;
    color: #e8e9f3;
    display: flex;
    justify-content: center;
    padding-top: 10px;
    background: #00aca7ad;
    border-radius: 10px
}
.th-not-exist p {
    width: 100%;
    align-self: center;
    font-size: 40px;
    font-weight: 500;
}
.pando-tabs {
  width: 100%;
  color: #000;
  
  .react-tabs{
    &__tab{
      border-bottom: 1px solid transparent;
      color: $color-font-light;
      &--disabled{
        cursor: not-allowed;
      }
    }

    &__tab-list{
      border-bottom: none;
      user-select: none;
    }

    &__tab--selected{
      background: $color-panel;
      color: $color-white;
      border-radius: 5px;
      border-color: $color-panel;
    }

    &__tab-panel--selected{
      .code-area, .abi-area{
        width: 100%;
        color: $color-font-light;
        resize: none;
        margin: 10px 0 20px 0;
      }
      .code-area{
        height: 350px;
        background: rgba(0, 0, 0, 0.5098039216) 0% 0% no-repeat padding-box;
        // box-shadow: inset -6px -6px 31px rgb(205 205 205 / 84%);
        padding: 20px;
        font-size: 16px;
        border-radius: 15px;
      }
      .abi-area{
        background: rgba(0, 0, 0, 0.5098039216) 0% 0% no-repeat padding-box;
        // box-shadow: inset -6px -6px 31px rgb(205 205 205 / 84%);
        padding: 20px;
        font-size: 16px;
        border-radius: 15px;
      }
    }
  }
  .read-contract{
    &__wrapper{
      margin-left: 0;
    }
  }
}
@font-face {
    font-family: 'helveticaneuemedium';
    src: url('../../frontend/public/fonts/helveticaneuemed-webfont.woff2') format('woff2'),
        url('../../frontend/public/fonts/helveticaneuemed-webfont.woff') format('woff'),
        /* url('../../frontend/public/fonts/HelveticaNeue.ttf') format('ttf'), */
        url('../../frontend/public/fonts/HelveticaNeueMedium.otf') format('otf');
}

@font-face {
    font-family: 'helveticaneuethin';
    src: url('../../frontend/public/fonts/helveticaneue-thin-webfont.woff2') format('woff2'),
        url('../../frontend/public/fonts/helveticaneue-thin-webfont.woff') format('woff'),
        url('../../frontend/public/fonts/HelveticaNeueThin.otf') format('otf'),
        url('../../frontend/public/fonts/HelveticaNeue-Thin.ttf') format('tff');

}

@font-face {
    font-family: 'helvetica_neueregular';
    src: url('../../frontend/public/fonts/helveticaneuebold-webfont.woff2') format('woff2'),
        url('../../frontend/public/fonts/helveticaneuebold-webfont.woff') format('woff'),
        url('../../frontend/public/fonts/HelveticaNeueBold.otf') format('otf');

}

html {
    background: #000;
}

body {
    font-family: 'Montserrat', sans-serif;
}

.thin-f {
    font-family: 'helveticaneuethin';
}

.bold-f {
    font-family: 'helvetica_neueregular';
}

.dropdown {
    display: inline-block;
    position: relative;
    float: right;
    z-index: 1;
    border-radius: 17px;
}

.dd-button {

    border-radius: 47px;
    opacity: 1;
    padding: 7px;
    font-size: 9px;
    width: 75%;
}

/* new header css 4th aug */
.new-hede.fixed-top {
    padding: 0px 90px;
    background: #000E1C;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.navbar-light.new-hede .navbar-brand {
    padding: 0px; 
}
.navbar-light.new-hede  .navbar-nav .nav-link {
    color: #959595;
    font-weight: 500;
}
.navbar-light.new-hede .navbar-nav .active>.nav-link{
    color: #00A6A6;
}
.navbar-light.new-hede .logo{
    margin-left: 0px;
}
.navbar-light.new-hede .test-nt{
    text-align: left;
}
.navbar-light.new-hede .test-nt a{
    color: #000;
}
.dropdown-item
{
    padding: 0;
}
.navbar-light.new-hede .dropdown-item.sgtd7 , .navbar-light.new-hede .dropdown-item {
    /* padding: 0px; */
    }
.navbar-light.new-hede .nav-link {
padding: 10px;
}
.navbar-light.new-hede .navbar-nav .nav-item{
padding: 0px;
}
.navbar-light.new-hede .navbar-nav .nav-item:last-child{
    padding-right: 0px;
}
/* .dd-button:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid white;
} */
.dd-button.search-select select {
    padding: 8px 28px 8px 10px;
    font-size: 16px;
    height: auto;
    margin-left: 0px;
    background: #2e2e2e 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 0px 22px #cdcdcd69;
    border-radius: 33px;
    width: 100%;
    line-height: inherit;
    font-family: 'helvetica_neueregular';
    cursor: pointer;
}

.dd-button:after {
    content: url(../public/images/icons/Icon-awesome-angle-down.svg);
    right: 15px;
    padding: 15px 0px 0px 8px;
    position: absolute;
    pointer-events: none;
}

.dd-button:hover {
    color: aqua
}

.dd-input {
    display: none;
}

.dd-menu {
    width: 200px !important;
    height: 200px;
    overflow-y: scroll;
    /* top: 43px !important; */
    border-radius: 15px;
    position: absolute;
    top: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    margin: 2px 0 0 0;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    list-style-type: none;
    /* color: white !important; */
    font-size: 15px;
    /* /* background-color: #300E57 !important; */
    /* background-image: linear-gradient(#300E57,#3E0874) !important; */
    /* background-image: linear-gradient(#5cb85c, transparent) !important; */

    /* background: transparent linear-gradient(180deg, #360046 0%, #004F51 100%) 0% 0% no-repeat !important; */
}



.dd-input+.dd-menu {
    display: none;
    background: transparent linear-gradient(180deg, #360046 0%, #004F51 100%) 0% 0% no-repeat padding-box;
}

.dd-input:checked+.dd-menu {
    display: block;
    background: transparent linear-gradient(180deg, #360046 0%, #004F51 100%) 0% 0% no-repeat padding-box;
}

.dd-menu li {
    padding: 10px 20px;
    cursor: pointer;
    white-space: nowrap;
}

.dd-menu li:hover {
    color: aqua;
    /* background-color: rgba(124, 129, 163, 0.35); */
}

.dd-menu li a {
    display: block;
    margin: -10px -20px;
    padding: 10px 20px;
}
.textsesconta
{
    display: inline;
    position: relative;
}
.dd-menu li.divider {
    padding: 0;
    border-bottom: 1px solid #cccccc;
}

.dropdown1 {
    z-index: 1;
    margin: 0px;
    text-transform: uppercase;
    -webkit-appearance: none !important;
    margin-top: 8px;

}

.download {
    /* width: 90%; */
    display: flex;
    background: #003139;
    margin: 0 auto;
    /* height: 501px; */
    border-radius: 7px;
}

.main-section {
    width: 90%;
    /* display: flex; */
    background: #003139;
    margin: 0 auto;
    height: 501px;
    top: 60px;
    border-radius: 7px;
}

.dd-button1 {
    -webkit-appearance: none !important;
    font-weight: 0;
    padding: 0.65rem;
    margin-right: 2%;
    color: #ffffff;
    font-size: 1rem;
    line-height: 1rem;
    letter-spacing: 3px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
}

.dd-button1:after {
    display: none;
}

.dd-button1:hover {
    color: aqua
}

.dd-input1 {
    display: none;
}

.dd-menu1 {
    border-radius: 17px;
    position: absolute;
    top: 100%;
    /* height: 55px; */
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 17px;
    margin: 2px 0 0 0;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    background: transparent linear-gradient(180deg, #360046 0%, #004F51 100%) 0% 0% no-repeat padding-box;
    list-style-type: none;
    /* color: black; */
}

.dd-input1+.dd-menu1 {
    display: none;
}

.dd-input1:checked+.dd-menu1 {
    display: block;
}

.dd-menu1 li {
    padding: 10px 20px;
    cursor: pointer;
    white-space: nowrap;
}

.dd-menu1 li:hover {
    /* color: #bc72ff;
    background-color: rgba(124, 129, 163, 0.35); */
}

.dd-menu1 li a {
    display: block;
    margin: -6px -28px;
    padding: 11px 20px;
}

.dd-menu1 li.divider {
    padding: 0;
    border-bottom: 1px solid #cccccc;
}

select,
option {
    width: 130px;
}

.total-rametron {
    color: aqua;
    /* margin-top: 3%; */
    font-weight: 600;
    font-size: 16px;
    margin: 46px 224px;
    height: 19px;

}

.custom-btn1 {
    font-weight: 600 !important;
    text-transform: none !important;
    width: 34%;
    background: transparent linear-gradient(90deg, #00FFFF 0%, #6bf6ff 27%, #E370FF 84%, #E26CFF 100%) 0% 0% no-repeat padding-box !important;
    margin: 20px;
    border-radius: 8px;



}

.nav-item imgs svg {
    fill: aqua;
}

.btn-width {
    width: 24% !important;
}

.rametronDownloads {
    font-size: 16px;
    border-radius: 33px;
    padding: 10px 15px;
    background: #00A6A6;
    opacity: 1;
    color: #fff;
    text-align: center;
}

.rametronDownloads a:hover {
    color: #fff !important;
}

.rametronDownloads li {
    list-style: none;
}

.rametronDownloads:hover {
    box-shadow: 0 0 11px rgba(201, 113, 113, 0.2);
    transition: 0.25s
}

.Icon_awesome-boxes:hover {
    fill: aqua;

}

.Icon_awesome-coins:hover {
    fill: aqua;
}

.imageContainerDownloads img {
    padding: 14px;
    left: 863px;
    bottom: 182px;
}

.imageContainerDownloads {
    display: flex;
    flex-direction: row;
}

.linkContainer {
    float: right;
    bottom: 74px;
    right: 315px;
}

.detailClass {
    /* padding-right: 4rem; */
}

/* dd-button search-select.dd-menu{
    background: transparent linear-gradient(180deg, #360046 0%, #004F51 100%) 0% 0% no-repeat padding-box;
} */

.tablenew {}


.global-spinner {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99999;
    text-align: center;
    background: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.global-spinner.active {
    display: none;
}

.copyw {
    cursor: pointer;
    transition: 0.3s all ease-in-out;

    z-index: 99;
}

.copyw:hover {
    transform: scale(1.1);
}

.frams {
    width: 100%;
    height: 400px;
    border-radius: 28px;
    /* margin: 15px; */
    border: none;
}
.legends
{
    display: flex;
    width: 100%;
    margin-top: 10px;
    align-items: flex-end;
}
.legends span{
    margin-right: 15px;
    display: inline-flex;
    position: relative;
    /* width: 25%; */
    padding-left: 20px;
    align-items: flex-end;
    color: #000;
}
.legends span:after{
    position: absolute;
    left: 0;
    top: 2px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    content: '';
    background-color: green;
}
.legends span.rtent:after
{
    background-color: red;
}

.legends span.rtpro:after
{
    background-color: #e52bdf;
}

.legends span.rtmobile:after
{
    background-color: blue;
}
.rtlist
{
    margin-top: 60px;
    display: flex;
    margin-left: 15px;
    margin-bottom: -10px;
}
.rtlist span
{
    display: inline-flex;
    padding: 15px 30px;
    border-radius: 6px;
    margin-right: 10px;
    background: rgba(0, 0, 0, 0.5098039216) 0% 0% no-repeat padding-box;
    box-shadow: inset 3px 0px 11px rgb(230 67 241 / 83%);
    color: #fff;
    margin-bottom: 10px;
    cursor: pointer;
    min-width: 100px
}
.rtlist span.active {
    background: #9e28a6 0% 0% no-repeat padding-box;
}
.rtlist span.disabled
{
    opacity: 0.7;
    cursor: not-allowed;
}
.pando-tabs .react-tabs__tab-list
{
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
}
.coubds
{
    text-transform: capitalize;
}
.nav-item.remaa.pnc20
{
    margin: 140px auto 0;
    width: 50%;
}
.nav-item.remaa.pnc20 .nav-link .column
{
    top: 35%;
}

@media(max-width: 991px)
{
    .rtlist span
    {
        padding: 15px 20px;
        min-width: auto;
    }
    .contese
    {
        padding-left: 0!important;
    }
    .textsesconta
    {
        position: absolute;
        top: 31px
    }
    .testsesx.onsa
    {
        display: none;
    }
    .textsesconta span
    {
        display: block;
        font-size: 12px;
    }
}
.xsdxs td
{
    padding-left: 20px!important;
}
.xsxswx598.blk9{
    margin-top: 15px;
}

table.txn-table2 tr .icon:before
{
    content: "";
    display: inline-block;
    position: relative;
    top: 2px;
    width: 32px;
    height: 20px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
}

table.txn-table2 tr .to.icon:before {
    background-image: url(../public/images/icons/Group1000001076.png);
}
table.txn-table2 tr .from.icon:before {
    background-image: url(../public/images/icons/Group1000001075.png);
}
.mnet6 table.data tbody td  a
{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    color: #00A6A6 !important;
}
.testsesx
{
    color: #b9b6b6;
    font-size: 14px;
    margin-right: 10px;
    vertical-align: middle;
}
.testsesx b
{
    color: #00A6A6 !important;

}
.h5ss
{
    margin: 45px 0;
    color: #000;
    text-align: center;
}
.details.token-info th p a,.s7fewe a{
    color: #00A6A6 !important;
}
.whsts.topnode6
{
    background-image: url(../public/images/icons/sdsd.png);
    position: absolute;
    width: 100%;
    top: 0;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    border-radius: 20px;
}

.walletana
{
    font-size: 20px;
    font-weight: 600;
    color: #000;
    margin: 15px 0px;
}
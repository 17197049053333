.content.transactions {
}

.content.transaction-details {
  .details-header {
    @include row($justify: space-between, $align: flex-end);
    padding: 3rem 1rem 0.75rem;
    .txn-type {
      color: #000000;
      /* letter-spacing: 3px; */
      /* text-transform: uppercase; */
      text-align: left;
      /* font-size: 22px; */
      font-weight: 500;
      letter-spacing: 0px;
      text-transform: capitalize;
      padding-left: 30px;
      &:before {
        top: 4px;
        margin-right: 0.75rem;
        border-radius: 100%;
      }
    }
    .btn.raw {
      margin-bottom: 0.125rem;
      color: #00A6A6;
      font-weight: $weight-regular;
      font-size: 14px;
    }
  }
  .coinbase-output {
    @include row($align: flex-start);
    border-bottom: 0px solid $color-line;
    padding: 0.5rem 0;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border-bottom: none;
    }
    & > *:first-child {
      margin-right: 1rem;
      white-space: nowrap;
      min-width: 30%;
      max-width: 50%;
    }
    & > a {
      display: block;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
    .currency {
      @include breakpoint("phablet", max) {
        flex-wrap: wrap;
      }
      @include breakpoint("phone-small", max) {
        &:before {
          display: none;
        }
      }
    }
  }
}

// In the global space since the icons are everywhere
.txn-type {
  &:before {
    content: "";
    display: inline-block;
    top: 4px;
    position: relative;
    width: 27px;
    height: 27px;
    margin-right: 0.5rem;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.txn-type.coinbase:before {
  background-image: url("../../../public/images/icons/icon_coinbase@2x.png");
  width: 22px;
  height: 22px;
  border-radius: 100%;
  display: none;
}
.txn-type.transfer:before {
  background-image: url("../../../public/images/icons/icon_send@2x.png");
  display: none;
}
.txn-type.service-payment:before {
  background-image: url("../../../public/images/icons/icon_servicepayment@2x.png");
}
.txn-type.split-contract:before {
  background-image: url("../../../public/images/icons/icon_splitrule@2x.png");
}
.txn-type.smart-contract:before {
  background-image: url("../../../public/images/icons/icon_smartcontract@2x.png");
}
.txn-type.reserve:before {
  background-image: url("../../../public/images/icons/icon_reservefund@2x.png");
}
.txn-type.release:before {
  background-image: url("../../../public/images/icons/icon_releasefund@2x.png");
}
.txn-type.rametron:before {
  background-image: url("../../../public/images/icons/icon_releasefund@2x.png");
}
.txn-type.slash:before {
  background-image: url("../../../public/images/icons/icon_slash@2x.png");
}
.txn-type.deposit-stake:before {
  background-image: url("../../../public/images/icons/icon_deposit-stake-transaction@2x.png");
  display: none;
}
.txn-type.withdraw-stake:before {
  background-image: url("../../../public/images/icons/icon_withdraw-stake-transaction@2x.png");
}

.txn-type.update-validators:before {
  background-image: url("../../../public/images/icons/icon_updatevalidators@2x.png");
}

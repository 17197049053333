#{$all-text-inputs},
textarea,pla {
  font-size: .875rem;
  padding: .5rem .5rem;
  background: white;
  // color: $color-input-text;
  border-radius: 5px;
  // height: 44px;
  border: none;

  &:focus {
    outline: 0;
  }
  &[disabled] {
    background-color: rgba($color-input-background, .20);
  }
  &::placeholder {
      color: grey;
      font-size: 14px;
  }
}

select {
  padding: 5px 10px;

  font-size: .75rem;
  line-height: .875rem;
  color: $color-input-text;
  font-weight: $weight-semi;

  background: transparent;
  border: 1px solid $color-input-border;
  border-radius: 0.25rem;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  &:focus {
    outline: 0;
  }
}

.select-container {
  
}


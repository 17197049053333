/**
 *
 * =======================================================================
     BASE SETTINGS & TYPOGRAPHY
 * =======================================================================
 *
 */

* {
  position: relative;
  box-sizing: border-box;

}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  // background-image: url("../../../public/images/Ellipse 4.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

html {
  font: {
    family: $font-body;
    weight: $weight-regular;
  }

  color: $color-font-main;
  line-height: 1.42857143;
  -webkit-font-smoothing: antialiased;
}

body {
  overflow: auto;
  // background: $color-page;
  hyphens: auto;
  word-wrap: break-word;
  color: $color-font-body;
}

/**
 *
 * =======================================================================
     Headings
 * =======================================================================
 */

h1,
h2,
h3,
h4,
h5,
h6 {
  // font-family: $font-title;
  color: $color-font-title;
  font-weight: $weight-regular;
  line-height: 1.1;
  margin: 0px 0px $line-height * 0.5rem;
  letter-spacing: 0px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  /*  voodoo to enable ligatures and kerning | https://developer.mozilla.org/en-US/docs/CSS/text-rendering */
}

h1,
h2,
h3 {
  letter-spacing: 0px;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  // font-size: 2.5rem;
  text-align: center;
  // float: left;
  /* margin-top: 57px; */
  margin-bottom: 57px;
  padding: 9px;
  // background: #1A6F7C 0% 0% no-repeat padding-box;
  /* background: aqua; */
  font-size: 30px;
  // border-radius: 3px;
  position: relative;
  // margin: 100px auto;
  // max-width: 600px;



}

.span {
  font-size: 48px;

}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 2rem;
}

h5 {
  font-size: 0.875rem;
}

h6 {
  font-size: 0.75rem;
}

h5,
h6 {
  font-weight: $weight-light;
}

.small {
  font-size: 0.75rem;
  font-weight: $weight-light;
}

.explore-1 h3 {
  font-size: 50px;
  text-align: center;
  margin-bottom: 0px;
}

.explore-1 h2 {
  font-size: 60px;
  color: #E643F1;
  padding: 0;
  margin-bottom: 20px;
}

.t1 {
  text-align: center;
  font-size: 20px;
  width: 75%;
  margin: 0 auto;
  margin-bottom: 60px;
  line-height: 30px;
}

.search-input.nwe1 {
  background: #3977c759 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 0px 20px rgb(205 205 205 / 84%);
  border-radius: 10px;
  opacity: 1;
  color: #fff;
  font-size: 16px;
  padding: 10px 10px;
  height: 50px;
  // width: 84%;
  margin-right: 10px;
  padding-right: 40px;
}

.search-input.nwe1::placeholder {
  color: #CCCCCC;
  font-size: 18px;
  font-family: 'helveticaneuethin';
}

.searchContainer {
  display: flex;
  width: 60%;
  margin: 0 auto;
}

.searchContainer select {
  background: #507ED0 0% 0% no-repeat padding-box;
  box-shadow: inset -4px -4px 31px rgb(205 205 205 / 84%);
  opacity: 1;
  padding: 13px 35px 13px 10px;
  font-size: 16px;
  line-height: inherit;
  border-radius: 10px;
  width: 145px;
  cursor: pointer;
  font-weight: 400;
}

.search-select .bi.bi-chevron-down {
  position: absolute;
  right: 10px;
  z-index: 1;
  top: 36%;
  pointer-events: none;
}

.search-select select option {
  margin: 40px;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.search-select select option[value="1"] {
  background: rgba(100, 100, 100, 0.3);
}

.vilot {
  color: #4E4E4E;
  font-weight: 400;
}

.overview.tab-bg {
  background: #00000082 0% 0% no-repeat padding-box;
  box-shadow: inset -6px -6px 31px #CDCDCDD6;
  border-radius: 29px !important;
  opacity: 1;
  // display:flex;
  width: 100%;
  border-radius: 5px;
  padding: 0px;
}

.overview.tab-bg.newtag {
  background: none;
  box-shadow: none;
}

table.data.block-table thead th,
table.data.txn-table2 thead th {
  font-size: 16px;
  font-weight: 500;
  padding-left: 0px;
}

table.data.block-table thead th p,
table.data.txn-table2 thead th p {
  background: rgb(0 105 187 / 35%) 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 0px 15px rgb(205 205 205 / 47%);
  border-radius: 55px;
  opacity: 1;
  padding: 10px 25px;
  margin-bottom: 0px;
  width: fit-content;
}

table.data.block-table tr td {
  font-size: 16px;
  padding: 10px;
  color: #00A6A6;
  background: white;
  border-bottom: 1px solid gainsboro;
}

table.txn-table2 tr td {
  font-size: 16px;
  background: white;
  border-bottom: 1px solid gainsboro;
  padding: 10px !important;
}

img.hyfrg {
  padding-right: 10px;
}

.data.block-table thead tr,
table.data.txn-table2 thead tr,
.content.transactions table.data.txn-table2 thead tr {
  border-bottom: 0px solid #fff;
}

.backgr thead tr {
  background: #fff;
}

.mor-lnk {
  text-align: center;
  border-radius: 6px;
  background: #E1FCFC;
  padding: 10px;
  margin: 30px 10px 0px;
}

.mor-lnk a {
  color: #00A6A6 !important;
  font-size: 16px;
}

.tbal-bl2,
.tbal-ty2 {
  // width: 50%;
  // padding: 25px;
  padding-bottom: 25px;
  border-radius: 8px;
  border: 0.5px solid #C6C4C4;
  background: #FFF;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  margin: 3px;

}

.tbal-bl2 table tbody,
.tbal-ty2 table tbody {
  background: rgba(65, 65, 65, 0.6705882353) 0% 0% no-repeat padding-box;
  opacity: 1;
}

.content.blocks .page-title,
.page-title.transactions {
  // background: #00000082 0% 0% no-repeat padding-box;
  box-shadow: inset -6px -6px 31px #cdcdcdd6;
  opacity: 1;
  padding: 9px;
  border-radius: 33px;
  text-transform: uppercase;
}

.content.blocks .custom-btn {
  border-radius: 100%;
  bottom: 0px;
  float: right;
  z-index: 0;
  padding: 2px 2px 0px;
  background: #00000082 0% 0% no-repeat padding-box;
  box-shadow: inset 4px 0px 11px #E643F1D4;
  opacity: 1;
}

.blk9 {
  // padding: 35px;
  // background: #00000082 0% 0% no-repeat padding-box;
  // box-shadow: inset -6px -6px 31px rgb(205 205 205 / 84%);
  border-radius: 29px !important;
  opacity: 1;
  // -webkit-
  // 
  // margin-top: 65px;
}

.backgr {
  border-radius: 10px;
  background: linear-gradient(180deg, #C9C9C9 0%, #FFF 16.83%);
  box-shadow: 0px 12px 27px 0px rgba(0, 0, 0, 0.25);
  width: 80%;
  margin: 0 auto;
  margin-top: -82px;
}

.backgr table.data.block-table tr td {
  color: #000000;
}

.green1 {
  color: #00A6A6 !important;
}

.backgr table.data.block-table thead th p {
  background: none;
  box-shadow: none;
  padding: 10px 0px;
  color: #000;
  ;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 500;
}

.content.blocks .data.block-table tbody,
.content.transactions .data.txn-table2 tbody {
  background: rgb(65 65 65 / 56%) 0% 0% no-repeat padding-box;
  opacity: 1;
}

.txn-table2 td.Coinbase.type {
  color: #000;
}

.txt-de2 {
  border-radius: 22px;
  background: #EFEFEF;
  box-shadow: none;
  opacity: 1;
  padding: 20px;
}

table.details tbody tr th {
  color: #000 !important;
  font-size: 16px !important;
}

table.data tbody tr.block-txn {
  border-bottom: 0px solid rgb(255, 255, 255) !important;
}

.txt-de2.nh6 {
  // padding: 45px 20px;
}

td.txn-type.hrt5.coinbase {
  text-transform: uppercase;
}

.content.transaction-details h3 {
  color: #000;
  text-align: left;
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0px;
  text-transform: capitalize;
  padding-left: 30px;
}

.content.transaction-details table.details.txn-info.table td {
  text-transform: capitalize;
}

.content.transaction-details table.details thead tr th:first-child,
.content.account table.details thead tr th:first-child {
  color: #000000 !important;
  font-weight: 500;
  padding-bottom: 0px;
  text-transform: capitalize;
}

.custom-btn.trans-54 {
  border-radius: 100%;
  bottom: 0px;
  float: right;
  z-index: 0;
  padding: 2px 2px 0px;
  background: rgba(0, 0, 0, 0.5098039216) 0% 0% no-repeat padding-box;
  box-shadow: inset 4px 0px 11px rgb(230 67 241 / 83%);
  opacity: 1;
  cursor: pointer;
}

.juh6 p .custom-btn.trans-54 img {
  width: 30px;
  padding-right: 0px;
  // margin-top/: 45px;
  cursor: pointer;
}

.content.transaction-details .details-header .txn-type {
  width: 100%;
  font-size: 22px !important;
  color: #ffffff;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.content.transaction-details .txn-info.table th {
  border-top: 0px solid #dee2e6;
  border: 0px;
  color: #000000 !important;
  // padding-bottom: 0px;
  text-transform: capitalize;
}

p.ac-det {
  background: none;
  box-shadow: none;
  color: #000;
  text-align: left;
  margin: 0px;
}

.content.account .stakes {
  margin-top: 30px;
}

.content.account .stakes .title {
  text-align: center;
  background: none;
  width: 100%;
  text-align: left;
}

.content.account .stakes .title p {
  background: none;
  box-shadow: none;
  opacity: 1;
  padding: 15px 0px;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 30px;
  color: #000;
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: 0px;
}

.stake-container td.node-type {
  text-align: left;
}

.stake-container table.data tbody tr,
.stake-container table.data.txn-table2 thead tr {
  border-bottom: 0px solid rgb(255, 255, 255) !important;
}

table.details.account-info tbody tr:last-child td {
  border-bottom: 0px solid #42455e;
}

table.data.txn-table2 thead tr {
  border-bottom: 0px solid #fff;
}

.stake-container td.address,
.stake-container td.status,
.stake-container td.node-type.gcp,
.stake-container td.token {
  text-align: left;
}

.token.for45 {
  float: right;
}

.stake-container .stake-tb {
  background: #41414199 0% 0% no-repeat padding-box;
  opacity: 1;

}

.juh6.react-tabs__tab--selected {
  background: no-repeat;
  color: #fff;
  font-size: 22px;
  text-transform: uppercase;
}

.juh6.react-tabs__tab--selected {
  list-style: none;
}

.fhg54-tb .react-tabs__tab-list,
.fhg54-tb table.data.txn-table2 thead tr,
.jh23 table.data.txn-table2 thead tr,
.content.stakes .data.txn-table2 thead tr {
  border-bottom: 0px solid #aaa;
}

.react-tabs__tab:focus:after {
  background: none !important;
}

.mnet6 {
  margin-top: 30px;
}

.customtansaciton button {
  border: 0;
  text-transform: uppercase;
  padding: 10px;
  border-radius: 6px;
  margin-right: 5px;
  color: #fff;
  width: 195px;
  margin-bottom: 10px;
}

.juh6 p {
  background: #00000082 0% 0% no-repeat padding-box;
  box-shadow: inset -6px -6px 31px rgb(205 205 205 / 84%);
  font-size: 22px;
  text-align: center;
  letter-spacing: 1px;
  width: 100%;
  margin-bottom: 30px;
  text-transform: uppercase;
  padding: 25px;
  border-radius: 33px;
  margin-left: 0;
}

.juh6 p img {
  width: 30px;
  height: 29px;
  padding-right: 10px;
}

.content.account .actions {
  text-align: right;
  display: inline-block;
  width: 20%;
  text-align: right;
}

.customtansaciton {
  display: inline-block;
  width: 80%;
  margin-bottom: -20px;
}

.content.account .actions:hover .btn.tx.export:before {
  // background-image: url("../../../public/images/icons/XMLID_1022_ (1).svg");
}

.fhg54-tb table tbody,
.jh23 table tbody,
.content.stakes table tbody {
  background: #41414199 0% 0% no-repeat padding-box;

}

img.sifr-img {
  width: 28px;
  height: 28px;
}

.stakes table tr td:first-child {
  padding-left: 10px !important;
}

.stakes table tr td:last-child {
  padding-right: 10px !important;
}

.hbd5 {
  text-transform: uppercase;
}

.gcp .hbd5:before,
.vcp .hbd5:before,
.rametronenterprisep .hbd5:before {
  content: "";
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 0.375rem;
  top: 2px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.gcp .hbd5:before {
  background-image: url("../../../public/images/icons/Metatron.svg");
  display: none;
}

.vcp .hbd5:before {
  // background-image: url("../../../public/images/icons/Group 4243.svg");
  display: none;
}

.rametronenterprisep .hbd5:before {
  background-image: url("../../../public/images/icons/Group 4243.svg");
  display: none;
}

.content.transactions .mnet6 {
  // margin-top: 60px;
  border-radius: 10px;
  background: linear-gradient(180deg, #C9C9C9 0%, #FFF 16.83%);
  box-shadow: 0px 12px 27px 0px rgba(0, 0, 0, 0.25);
  width: 95%;
  margin: 0 auto;
  margin-top: -82px;
  padding: 0px;
}

.content.transactions .custom-btn,
.custom-btn.hom-ref {
  border-radius: 100%;
  bottom: 0px;
  float: right;
  z-index: 0;
  padding: 2px 2px 0px;
  background: #00000082 0% 0% no-repeat padding-box;
  box-shadow: inset 4px 0px 11px rgb(230 67 241 / 83%);
  opacity: 1;

}

.custom-btn.hom-ref {
  top: 15px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
}

.chart-container.hyg {
  box-shadow: none;
  // height: 100%;
  background: rgb(0 0 0 / 0%) 0% 0% no-repeat padding-box;
}

.hyg .doughnut {
  margin-top: 30px;
}

h3.blk-det {
  background: #00000082 0% 0% no-repeat padding-box;
  box-shadow: inset -3px -3px 31px rgb(233 1 249 / 49%);
  opacity: 1;

  padding: 20px 80px;
  border-radius: 33px;
  width: fit-content;
  margin: 0 auto;
}

.stakes .title.u7u {
  background: #00000082 0% 0% no-repeat padding-box;
  box-shadow: inset -6px -6px 31px #cdcdcdd6;
  border-radius: 47px;
  opacity: 1;

  padding: 15px 20px;
  font-size: 18px;
}

.stakes.trnaj table {
  text-align: left;
}

.stakes.trnaj .staked-prct {
  text-align: inherit !important;
}

#app-content .content.transaction-details .page-title {
  box-shadow: none;
}

.csdcsd {
  color: #76ebff;
}

.custom-btn.cont-u8 {
  position: absolute;
  top: 5%;
  height: 38px;
  width: 38px;
  right: 0;
  border-radius: 100%;
  bottom: 0px;
  float: right;
  /* z-index: 0; */
  padding: 2px 2px 0px;
  background: #00000082 0% 0% no-repeat padding-box;
  box-shadow: inset 4px 0px 11px rgb(230 67 241 / 83%);
  opacity: 1;

  z-index: 1;
}

.gsrf {
  width: 100%;
  display: flex !important;

}

.gsrf p {
  width: 50%;
  font-size: 16px;
}

.gsrf p:first-child {
  text-align: left;
  letter-spacing: 1px;
}

.gsrf .ptx {
  margin-top: 0px;
}

.gsrf img {
  width: 18px;
}

#app-content .content .page-title.ui7 {
  box-shadow: none;
}

.page-title.transactions.ui7 {
  padding: 9px;
}

.overview.tab-bg .data.block-table thead tr {
  border-bottom: 0px solid #fff;
}

.chk-rem h2 {
  background: #00000082 0% 0% no-repeat padding-box;
  box-shadow: inset -3px -3px 31px rgb(233 1 249 / 49%);

  padding: 15px 30px;
  border-radius: 33px;
  font-size: 22px;
  width: fit-content;
  margin: 0 auto;
}

.nod-st .row {
  background: #00000082 0% 0% no-repeat padding-box;
  box-shadow: inset -6px -6px 31px rgb(205 205 205 / 84%);
  border-radius: 29px;
  opacity: 1;

  padding: 60px;
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
}

.nod-st .row .col-lg-6 {
  align-self: center;
}

.rametron-section {
  display: flex;
  justify-content: center;
}

.ip-input {
  margin-top: 15px;
  width: 85%;
  height: 50px;
  border: 1px dashed gray !important;
  border-radius: 33px !important;
}

.ip09 .ip-input {
  margin-top: 15px;
  width: 85%;
  height: 60px;
  border: 0px gray !important;
  border-radius: 33px !important;
  /* padding: 15px 10px!important; */
  color: #0a0a0a !important;
  font-size: 16px;
}

.ip09 p:first-child {
  font-size: 20px;
}

.ip09 p.dis98 {
  font-size: 16px;
  color: #AFAFAF;
  font-family: "helveticaneuethin";
  margin-top: 15px;
}

.btn.custom-ip-btn {
  margin-top: 15px;
  color: white;
  padding: 15px 50px;
  border-radius: 6px;
  background: #00000082 0% 0% no-repeat padding-box;
  box-shadow: inset -6px -6px 31px rgb(205 205 205 / 84%);
  border-radius: 22px;
  opacity: 1;

  text-transform: capitalize;
}

.rametron-section img {
  width: 80% !important;
}

.anounce-sec img {
  width: 60px;
}

// trim data in table

table.data tbody td.overflow a {
  width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  color: #00A6A6 !important;
}

table.data tbody td.Smart {
  color: #000;
}

table.data tbody td.overflow.hasgfr4 a {
  align-self: center;
}

table.data.block-table tr .height {
  color: #00A6A6 !important;
}

.ip-btn-left .custom-ip-btn {
  width: auto;
}

// public node page css

.pub2 p {
  background: #00A6A6;
  box-shadow: inset -6px -6px 31px rgba(205, 205, 205, 0.84);
  font-size: 18px;
  text-align: center;
  letter-spacing: 1px;
  width: 100%;
  margin-bottom: 30px;
  text-transform: uppercase;
  padding: 12px;
  border-radius: 33px;
  font-weight: 500;
}

.pub2 img {
  margin-right: 9px;
  width: 32px;
  margin-top: -8px;
  display: none;
}

.content.public-node .data.block-table tbody {
  background: rgba(65, 65, 65, 0.6705882353) 0% 0% no-repeat padding-box;
  opacity: 1;

}

.no-po {
  text-align: center;
}

.no-po {
  text-align: center;
}

.no-po h3 {
  font-size: 22px;
  margin-bottom: 30px;
}


// table css
table.data tbody tr:nth-child(2n) {
  background: rgba(65, 65, 65, 0.6) 0% 0% no-repeat padding-box;


}

table.data tbody tr {
  padding: 10px;
}

table tr td {
  font-size: 16px;
  // padding: 15px!important;
}

table.data tbody tr {
  border-bottom: 0px solid rgba(255, 255, 255, 0.1607843137) !important;
}

table.data tbody tr {
  background-color: rgba(214, 216, 255, 0) !important;
  border-bottom: 0px solid rgba(255, 255, 255, 0.1607843137);
  // box-shadow: inset 12px 7px 12px rgb(205 205 205 / 23%);
}

.data.block-table,
.data.txn-table2,
.txn-info.details,
.details.txn-info,
.data.transactions,
.details.account-info,
.data.txn-table2 {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
}

.coin-b2 {
  width: 17px;
  height: 17px;
  margin-right: 8px;
  border-radius: 100%;
}

.Norecordfound {
  background: rgba(65, 65, 65, 0.6) 0% 0% no-repeat padding-box;
  padding: 10px;
  text-align: center;
}

.backgr table.data.block-table tr .height.blck {
  color: #000 !important;
}

td.fee {
  color: #00A6A6 !important;
}

// Export ui css

.content.account .actions .popup-content {
  top: auto !important;
  left: 0px !important;
  right: 0;
  margin: 0 auto;
}

.content.account .actions .popup-content .popup-arrow {
  transform: rotate(229deg) !important;
  top: -6px !important;
  left: 0 !important;
  right: 0;
  margin: 0 auto;
}

// Buttons active and foucs

.custom-btn.cont-u8:not(:disabled):not(.disabled):active:focus {
  box-shadow: inset 4px 0px 11px rgb(230 67 241 / 83%);
  background-color: rgba(0, 0, 0, 0.5098039216) 0% 0% no-repeat padding-box;
  border-color: inset 4px 0px 11px rgb(230 67 241 / 83%);
}


// table data contract
.smrt54 table tbody tr:last-child td {
  background-color: #343434b5;
  padding: 20px;
}

/**
 *
 * =======================================================================
     Paragraphs and Indentation
 * =======================================================================
 */

p {
  margin: 9px 9px 10px;
  /* bottom margin for paragraphs */
  hyphens: auto;
  line-height: 1.375rem;
  // font-weight: $weight-light;
  font-size: 16px;
}

p+p {
  margin-top: 1em;
  /* no top margin on subsequent paragraphs */
}

/**
 *
 * =======================================================================
     Links
 * =======================================================================
 */

a,
a:visited {
  text-decoration: none;
  // color: $color-font-link;
  transition: all 0.2s;

  i {
    transition: all 0.2s;
  }

  &:hover {
    color: $color-font-link-hover;
  }
}

p a {
  text-decoration: none;
}

/**
 *
 * =======================================================================
     Hyphens
 * =======================================================================
 */

abbr,
acronym,
blockquote,
code,
dir,
kbd,
listing,
plaintext,
q,
samp,
tt,
var,
xmp {
  hyphens: none;
}

/**
 *
 * =======================================================================
     Selection
 * =======================================================================
 */

::selection {
  background-color: $color-select-back !important;
  color: $color-select-type !important;
  /* WebKit/Blink Browsers */
  text-shadow: none;
}

::-moz-selection {
  background-color: $color-select-back !important;
  color: $color-select-type !important;
  /* Gecko Browsers */
  text-shadow: none;
}


@media (max-width: 991px) {
  #app-content .content .page-title {
    margin-top: 0rem !important;
    font-size: 18px !important;
  }

  .token.dashboard.PTX {
    margin-top: 1rem;
  }

  .custom-btn.cont-u8 {
    left: auto !important;
  }

  table.data.block-table thead th p,
  table.data.txn-table2 thead th p {
    padding: 5px 10px;
    margin-bottom: 0px;
    font-size: 12px;
    margin-left: 0;
    // background:transparent;
    // box-shadow: none;
    border-radius: 55px;
    opacity: 1;
  }

  .content.account .stakes .title p {
    font-size: 14px;
    padding: 15px 10px
  }

  table.data tbody td.overflow a {
    width: auto;
  }

  .txt-de2 {
    padding: 10px;
    margin-top: 15px !important;
  }

  .chart-container.hyg {
    // height: 100%;
    margin-top: 0;
  }

  .stakes.trnaj table.data tbody td {
    padding: 5px !important;
  }

  .blk9 {
    padding: 0;
    // margin-t/op: 15px;
  }

  table.data tbody td,
  table.data tbody th {
    font-size: 12px;
  }

  .gsrf .ptx {
    margin: 0;
    margin-top: 8px;
  }

  .pub2 p {
    padding: 15px;
    font-size: 14px;
    margin-left: 0;
  }

  .no-po h3 {
    padding-top: 20px;
    margin-bottom: 10px;
  }

  .custom-btn.hom-ref {
    top: 0
  }

  .download-page {
    padding: 0 !important
  }

  .download-page .h-100 {
    padding: 15px !important
  }

  .download-page-network .col-lg-12 {
    padding: 0;
  }

  .download-page-network .box {
    padding: 10px !important
  }

  .download-page-network .box h4 {
    font-size: 22px !important;
  }

  .download-page-network .box p {
    font-size: 18px !important;
    padding: 10px 0 !important;
    line-height: normal !important;
    display: none;
  }

  .download-page-network .icon-flex {
    display: block !important;
  }

  .download-page-network .icon-flex button {
    text-align: center !important;
    width: 100%;
  }

  .download-page .download-box .title {
    font-size: 22px !important;
  }

  .download-page .download-box .des {
    font-size: 16px !important;

  }

  .download-page .download-box button {
    padding: 15px 30px !important;
  }

  .chart-container.homechart {
    display: none;
  }

  .overview.tab-bg .row.w-100 {
    margin: 0
  }

  .tbal-bl2 .page-title.blocks,
  #app-content .content .page-title.ui7 {
    box-shadow: inset -6px -6px 31px rgb(205 205 205 / 84%);
    opacity: 1;

    border-radius: 29px;
  }

  .hash.overflow a {
    width: 150px !important;
  }

  .token.dashboard .column .detail {
    margin: 8px 0px !important;
  }

  .token.dashboard .column {
    padding-top: 0px !important;
  }

  .search-input.nwe1 {
    width: 100%;
  }

  .searchContainer,
  .searchContainer select {
    width: 100% !important;
    display: block !important;
    margin-top: 10px;
  }

  .do-bth .rametronDownloads {
    margin-top: 10px;
  }

}
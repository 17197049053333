.content.account {
  table.details.account-txns {
    margin-top: 2rem;
    tbody th {
      width: 200px;
    }
    tbody tr td div {
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }

  .customtansaciton
  {
    .active{
    background: rgb(0 105 187 / 84%) 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 0px 15px rgb(205 205 205 / 47%);
    opacity: 1;
    color: #fff;
    border: 0;
    text-transform: capitalize;
    padding: 10px;
    border-radius: 4px;
    margin-right: 5px;
    margin-bottom: 10px;
    border-radius: 10px;
    background: #00A6A6;
    }
  }
  .actions {
    

    padding-top: 2rem;
    .popup-content {
      width: 330px !important;
      background: transparent linear-gradient(180deg, #1d252d, #4d5d6d) 0 0 no-repeat padding-box !important;
      color: $color-font-light;
      padding: 10px !important;
      .popup-row {
        display: flex;
        margin-bottom: 10px;
        &.header {
          display: block;
          color: $color-white;
          font-weight: bold;
        }
        &.err-msg {
          color: $color-red;
          padding-left: 30%;
          &.disable {
            display: none;
          }
        }
        &.buttons {
          display: flex;
          justify-content: space-around;
          .popup-reset {
            width: 30%;
            margin: 10px 0 0 0;
            font-size: 0.875rem;
            font-weight: $weight-semi;
            color: $color-font-light;
            cursor: pointer;
            border: 1px solid $color-font-light;
            text-align: center;
            border-radius: 15px;
          }
          .popup-download {
            width: 30%;
            margin: 10px 0 0 0;
            font-size: 0.875rem;
            font-weight: $weight-semi;
            color: $color-font-light;
            cursor: pointer;
            border: 1px solid $color-font-light;
            text-align: center;
            border-radius: 15px;
          }
          .popup-downloading {
            width: 40%;
            margin: 10px 0 0 0;
            font-size: 0.875rem;
            font-weight: $weight-semi;
            color: $color-font-light;
            text-align: center;
          }
        }
        .disable {
          display: none;
        }
      }
      .popup-label {
        width: 30%;
        vertical-align: baseline;
        margin: auto;
      }
      .popup-input {
        width: 70%;
      }
      .popup-arrow {
        background: transparent linear-gradient(180deg, #1d252d, #4d5d6d) 0 0 no-repeat padding-box !important;
        border-right: 1px solid $color-font-light;
        border-bottom: 1px solid $color-font-light;
        display: none;
      }
    }
    .download {
      // position: absolute;
      left: 0;
      color: $color-font-light;
      cursor: pointer;
      @include breakpoint("phablet", max) {
        @include hideColumn();
      }
    }
    .title {
      position: absolute;
      left: calc(50% - 44px);
      color: #000;
      font-weight: 500;
      font-size: 18px;
      top: 5px;
    }
    .switch {
      @include breakpoint("phablet", max) {
        display: none;
      }
    }
    .btn.tx {
      font-size: 0.875rem;
      font-weight: $weight-semi;
      color: $color-font-light;
      &.export {
        &:before {
          content: "";
          display: inline-block;
          position: relative;
          width: 16px;
          height: 16px;
          margin-right: 0.375rem;
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url("../../../public/images/icons/Page-1.svg");
        }
      }
    }
  }
 
  table.data.txn-table,
  & > .loading-panel {
    margin-top: 0rem;
  }

  @include breakpoint("tablet", max) {
    table.details.account-txns {
      tbody tr th {
        content: "Transactions";
      }
    }
  }
}

.act.balance {
  display: flex;
  flex-direction: row;
  .currency {
    width: 50%;
    display: flex;
  }
  .currency:before {
    display: none;
  }
  .PandoWei {
    display: none;
  }

  @media only screen and (max-width: 559px) {
    .PandoWei {
      display: none !important;
    }
  }

  @include breakpoint("phablet", max) {
    flex-direction: column;
    .currency {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
  }
}
.price {
  margin-left: 5px;
  color: $color-light;
  display: none;
}

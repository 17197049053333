.pagination {
  @include row($justify: center);

  margin: 2rem auto 0;

  .btn {
    flex: 0 0 auto;
    font-family: $font-body;
    margin-right: 0.875rem;

    &:last-child {
      margin-right: 0rem;
    }
    &.active {
      color: #E643F1;
    }
    &.first,
    &.last,
    &.prev,
    &.next {
      padding-left: 0;
      padding-right: 0;
      i {
        display: block;
        width: 32px;
        height: 32px;
        margin: 0;
        top: -1px;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &.first {
      margin-right: 1.5rem;
    }
    &.last {
      margin-left: 1.5rem;
    }
    &.next {
      margin-left: 2rem;
    }
    &.prev {
      margin-right: 2rem;
    }
  }
  .ellipse {
    margin-right: 1rem;
    color: $color-font-light;
    &[disabled] {
      opacity: 0.5;
    }
  }

  @include breakpoint("tablet", max) {
    .btn {
      margin-right: 0.25rem;
      //font-size: .875rem;
      &.first,
      &.prev,
      &.last,
      &.next {
        i {
          width: 24px;
          height: 24px;
        }
      }
      &.first {
        margin-right: 0.25rem;
      }
      &.prev {
        margin-right: 0.5rem;
      }
      &.last {
        margin-left: 0.25rem;
      }
      &.next {
        margin-left: 0.5rem;
      }
    }
  }
  @include breakpoint("phone-wide", max) {
    .btn.first,
    .btn.last {
      display: none;
    }
  }
}

.code-loading-text{
    position: absolute;
    top: 10%;
    width: 100%;
    text-align: center;
  }
  .selects-container{
    display: flex;
    justify-content: space-between;
  }
  .smskfn
  {
    text-align: right;
  }
  .select{
    &__container{
      display: flex;
      margin: 15px 0;
      & label{
        line-height: 32px;
        margin-top: 7px;
      }
    }
    &__selector{
      width: 12rem;
      margin: 0 5px;
      &.optimizer{
        width: 10rem;
      }
      &::after {
        content: "";
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 6px solid $color-input-text;
        position: absolute;
        right: 5px;
        top: 18px;
        pointer-events: none;
      }
      & select{
        width: 100%;
        height: 32px;
        color: white;
        &.isEmpty{
          border-color: $color-red;
        }
      } & select option{
        color: #fff;
      }
    }
  
    &__tooltip{
      $self: &;
      margin: 0 5px;
      z-index: 100;
      &.question-mark{
        display: inline-block;
        color: $color-font-light;
        border: 1px solid $color-font-light;
        border-radius: 50%;
        width: 0.875rem;
        height: 0.875rem;
        text-align: center;
        line-height: 0.875rem;
        font-size: 0.875rem;
      }
      &--text{
        width: 250px;
        visibility: hidden;
        position: absolute;
        z-index: 1;
        background: $color-font-light;
        border-radius: 6px;
        padding: 8px 0 5px 10px;
        text-align: left;
        line-height: 15px;
        color: $color-white;
        font-size: 12px;
        background: rgba(0, 0, 0, 0.5098039216) 0% 0% no-repeat padding-box;
        box-shadow: inset -6px -6px 31px rgb(205 205 205 / 84%);
  
        bottom: calc(100% + 10px);
        left: 50%;
        margin-left: -125px;
        &:after{
          content: '';
          position: absolute;
          border-style: solid;
          top: 100%;
          left: 50%;
          margin-left: -5px;
          border-color: $color-font-light transparent transparent transparent;
          border-width: 5px;
        }
      }
      &:hover #{ $self }--text{
          visibility: visible;
      }
    }
  }
  .code-buttons{
    display: flex;
    justify-content: center;
    padding: 0 20%;
    & div{
      padding: 14px 13px;
      color: #ffffff;
      background: #00A6A6;
      border: none;
      border-radius: 0.25rem;
      font-weight: 500;
      cursor: pointer;
      border-radius: 33px;
      margin-right: 10px;
      &:hover{
        box-shadow: 0 4px 8px 0 rgba($color-blue, 0.2), 0 6px 20px 0 rgba($color-blue, 0.19);
        background: #00A6A6 0% 0% no-repeat padding-box;
      }
      &.reset{
        background: #2e2e2e 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 0px 22px #cdcdcd69;
        // width: 120px;
        text-align: center;
        &:hover{
          background-color: $color-input-background;
          box-shadow: 0 4px 8px 0 rgba($color-input-background, 0.2), 0 6px 20px 0 rgba($color-input-background, 0.19);
        }
      }
    }
  }
  .code-error-text{
    margin-bottom: 30px;
    &:before{
      display: inline-block;
      content: '!';
      border: 1px solid $color-red;
      width: 1em;
      height: 1em;
      line-height: calc(1em + 3px);
      text-align: center;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  .contract-info{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    color: #000;
    background-color: $color-panel;
    &__title{
      display: flex;
      height: 40px;
      line-height: 40px;
      margin: 20px 0;
      font-weight: $weight-bold;
      &--sub{
        color: $color-font-light;
        font-size: 0.875rem;
        font-weight: $weight-regular;
      }
      &.verified{
        margin-top: 0;
        color: $color-green;
      }
    }
    &__general{
      display: flex;
      flex-direction: row;
      @include breakpoint('phablet', max) {
        flex-direction: column;
      }
    }
    &__raws{
      width: 50%;
      padding: 0 1rem 0 1rem;
      &:first-child{
        padding-right: 3rem;
      }
      @include breakpoint('phablet', max) {
        width: 100%;
      }
    }
    &__cell{
      display: flex;
      line-height: 40px;
      &:first-child{
        border-bottom: 1px solid $color-light;
      }
      & > div{
        width: 40%;
        &:nth-child(2){
          flex: 1;
          color: $color-font-light;
        }
      }
    }
    &__block{
      margin: 10px 0;
      width: 100%;
    }
  }
  .setting-section {
    width: 33.3%;
    & label {
      & input, select {
        width: 100%;
        margin-top: 5px;
      }
    }
    &__tooltip{
      $self: &;
      margin: 0 5px;
      z-index: 100;
      &.question-mark{
        display: inline-block;
        border: 1px solid $color-font-light;
        color: $color-font-light;
        border-radius: 50%;
        width: 0.875rem;
        height: 0.875rem;
        text-align: center;
        line-height: 0.875rem;
        font-size: 0.875rem;
      }
      &--text{
        width: 250px;
        visibility: hidden;
        position: absolute;
        color: $color-white;
        z-index: 1;
        background: $color-font-light;
        border: 1px solid $color-font-light;
        border-radius: 6px;
        padding: 8px 0 5px 10px;
        font-size: 12px;
        text-align: left;
        line-height: 15px;
  
        bottom: calc(100% + 10px);
        left: 50%;
        margin-left: -125px;
        &:after{
          content: '';
          position: absolute;
          border-style: solid;
          top: 100%;
          left: 50%;
          margin-left: -10px;
          border-color: $color-font-light transparent transparent transparent;
          border-width: 10px;
        }
      }
      &:hover #{ $self }--text{
          visibility: visible;
      }
    }
  }